import { ORDERED_COUNTRIES } from "constants/sku-grouping";
import { CwItemDescription } from "types/item";

export function filterExportMarket(taskRequirements: CwItemDescription[]) {
  let usIndex;
  let euIndex;
  let ukIndex;
  taskRequirements?.forEach((item, index) => {
    if (item.exportMarket === "US") {
      usIndex = index + 1;
    }

    if (item.exportMarket === "EU") {
      euIndex = index + 1;
    }

    if (item.exportMarket === "UK") {
      ukIndex = index + 1;
    }
  });

  if (usIndex) {
    return taskRequirements[usIndex - 1];
  }

  if (euIndex) {
    return taskRequirements[euIndex - 1];
  }

  if (ukIndex) {
    return taskRequirements[ukIndex - 1];
  }

  return taskRequirements[0];
}

export function sortedExportMarket(list: string[] | undefined = []): string[] {
  return list.sort((a: string, b: string) => ORDERED_COUNTRIES[a] - ORDERED_COUNTRIES[b]);
}
