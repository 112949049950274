import React, { useState, useEffect } from "react";

import {
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Checkbox,
  Button,
  Box,
  TextField,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";

import { TransferListValue } from "types/global";
import camelCaseTextToWord from "utils/camelCaseTextToWord";

import Text from "../Text";

export interface TransferListProps {
  actionRequired?: {
    defaultView?: "left" | "right" | "both";
    selectAll?: "left" | "right" | "both";
    clearSelected?: "left" | "right" | "both";
  };
  columnView?: string;
  leftText?: string;
  rightText?: string;
  isAsyncData?: boolean;
  isNameAsKey?: boolean; // while name as key i.e euStatus, srcNum...
  defaultLeft?: TransferListValue[];
  defaultRight?: TransferListValue[];
  currentSavedList?: TransferListValue[];
  selectedCheckIcon?: boolean;
  defaultListLength?: number;
  columnViewDisabled?: boolean;
  allCheckDisabled?: boolean;
  orderCheckDisabled?: boolean;
  checkbox?: boolean;
  loading?: boolean;
  onUpdate: ({ left, right }: { left: TransferListValue[]; right: TransferListValue[] }) => void;
  onInputChange?: (value: string) => void;
}

function not(a: readonly TransferListValue[], b: readonly TransferListValue[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly TransferListValue[], b: readonly TransferListValue[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function reorder(checked: TransferListValue[], fromIndex: number, order: "up" | "down") {
  const reorderItem = checked?.filter((_, index: number) => index === fromIndex)[0];

  const toIndex = order === "up" ? fromIndex - 1 : fromIndex + 1;

  const updatedChecked = checked.filter((_, index) => index !== fromIndex);

  updatedChecked.splice(toIndex, 0, reorderItem);

  return updatedChecked;
}

export default function TransferList({
  actionRequired,
  columnView = "",
  leftText,
  rightText,
  isAsyncData = false,
  isNameAsKey = false,
  defaultLeft = [],
  defaultRight = [],
  currentSavedList = [],
  defaultListLength = 0,
  columnViewDisabled = false,
  allCheckDisabled = false,
  orderCheckDisabled = false,
  selectedCheckIcon = false,
  checkbox = false,
  loading = false,
  onUpdate,
  onInputChange,
}: TransferListProps) {
  const [checked, setChecked] = useState<readonly TransferListValue[]>([]);
  const [left, setLeft] = useState<TransferListValue[]>(defaultLeft ?? []);
  const [right, setRight] = useState<TransferListValue[]>(defaultRight ?? []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const getCheckedIndexes = (property: "left" | "right") => {
    const selected = property === "left" ? left : right;
    const selectedChecked = property === "left" ? rightChecked : rightChecked;

    return selected?.reduce((acc: number[], cur: TransferListValue, index: number) => {
      if (selectedChecked.includes(cur)) {
        acc.push(index);
      }

      return acc;
    }, []);
  };

  // only support reorder 1 item
  const canRightCheckedOrder = right?.length && rightChecked?.length === 1;
  const canRightCheckedOrderUp = !!getCheckedIndexes("right")[0];
  const canRightCheckedOrderDown = rightChecked.length !== getCheckedIndexes("right")[0];

  const handleSetDefaultList = () => {
    setLeft(defaultLeft);
    setRight(currentSavedList ?? defaultRight);

    onUpdate({ left: defaultLeft, right: currentSavedList ?? defaultRight });
  };

  const handleClearCheck = (direction: "left" | "right") => {
    if (direction === "left") {
      setChecked((state) => state?.filter((item) => !left?.includes(item)));
    } else {
      setChecked((state) => state?.filter((item) => !right?.includes(item)));
    }
  };

  const handleSelectAll = (direction: "left" | "right") => {
    if (direction === "left") {
      setChecked((state) => [...new Set([...state, ...left])]);
    } else {
      setChecked((state) => [...new Set([...state, ...right])]);
    }
  };

  const handleToggle = (value: TransferListValue) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleOrderUp = () => {
    const currentIndexes = getCheckedIndexes("right")[0];

    const updatedList = reorder(right, currentIndexes, "up");

    setRight(updatedList);

    onUpdate({ left, right: updatedList });
  };

  const handleOrderDown = () => {
    const currentIndexes = getCheckedIndexes("right")[0];

    const updatedList = reorder(right, currentIndexes, "down");

    setRight(updatedList);

    onUpdate({ left, right: updatedList });
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);

    onUpdate({ left: right.concat(left), right: [] });
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    onUpdate({ left: not(left, leftChecked), right: right.concat(leftChecked) });
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    onUpdate({ left: left.concat(rightChecked), right: not(right, rightChecked) });
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);

    onUpdate({ left: left.concat(right), right: [] });
  };

  const customList = ({
    direction,
    items,
    nameActionEnabled,
    actionEnabled,
  }: {
    direction: "left" | "right";
    items: readonly TransferListValue[];
    nameActionEnabled?: boolean;
    actionEnabled?: boolean;
  }) => {
    const clearSelectDisabled =
      (actionRequired?.clearSelected === "both" && !checked?.length) ||
      !!(
        direction === "left" &&
        actionRequired?.clearSelected === "left" &&
        !leftChecked?.length
      ) ||
      !!(
        direction === "right" &&
        actionRequired?.clearSelected === "right" &&
        !rightChecked?.length
      );

    const selectAllDisabled =
      (actionRequired?.clearSelected === "both" && (!left?.length || !right?.length)) ||
      !!(direction === "left" && actionRequired?.selectAll === "left" && !left?.length) ||
      !!(direction === "right" && actionRequired?.selectAll === "right" && !right?.length);

    const hasLeftActions =
      actionRequired &&
      !!Object.keys(actionRequired)?.length &&
      Object.values(actionRequired)?.includes("left");

    const hasRightActions =
      actionRequired &&
      !!Object.keys(actionRequired)?.length &&
      Object.values(actionRequired)?.includes("right");

    const isRightSelectedCheckIcon = selectedCheckIcon && direction === "right";

    const renderList = (
      <List dense component="div" role="list">
        {items?.map((value: TransferListValue, index: number) => {
          const labelId = `transfer-list-item-${index}-label`;

          const isChecked = checked.indexOf(value) !== -1;

          return checkbox ? (
            <ListItemButton
              key={index}
              sx={{
                display: "flex",
                flexFlow: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "8px 12px",
              }}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={isChecked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                  disabled={loading}
                />
              </ListItemIcon>

              {/* <ListItemText id={labelId} primary={value?.value} /> */}

              <Text size="text" color="black">
                {isNameAsKey ? camelCaseTextToWord(value?.name) : value?.name}
              </Text>

              {value?.info && (
                <Text size="text" color="gray">
                  {value?.info}
                </Text>
              )}
            </ListItemButton>
          ) : (
            <ListItemButton
              key={index}
              role="listitem"
              sx={{
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px 12px",
              }}
              onClick={handleToggle(value)}
              selected={checked.includes(value)}
              disabled={loading}
            >
              {/* <ListItemText id={labelId} primary={isNameAsKey ? camelCaseTextToWord(value?.name) : value?.name} /> */}

              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Text size="text" color="black">
                  {isNameAsKey ? camelCaseTextToWord(value?.name) : value?.name}
                </Text>

                {value?.info && (
                  <Text size="text" color="gray">
                    {value?.info}
                  </Text>
                )}
              </Box>

              {isRightSelectedCheckIcon && isChecked && <DoneIcon />}
            </ListItemButton>
          );
        })}
        <ListItem />
      </List>
    );

    const isCurrutDirectionAndEmptyList =
      (direction === "right" && !right?.length) || (direction === "left" && !left?.length);

    return (
      <>
        <Paper
          elevation={2}
          sx={{
            width: "100%",
            height: "280px",
            overflow: "auto",
            marginTop: "8px",
            backgroundColor: isCurrutDirectionAndEmptyList ? "#F2F2F2" : "#fff",
          }}
        >
          {isCurrutDirectionAndEmptyList ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
                height: "100%",
              }}
            >
              <Text size="text" color="gray">
                No Item Selected
              </Text>
            </Box>
          ) : (
            renderList
          )}
        </Paper>

        {nameActionEnabled && (
          <Grid
            sx={{ display: "flex", alignItems: "center", marginTop: "4px" }}
            container
            spacing={0.5}
          >
            <Grid item xs={12}>
              Column View Name:&nbsp;
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{ input: { padding: "8px" } }}
                fullWidth
                name="columnView"
                value={columnView}
                onChange={(event) => {
                  if (onInputChange) onInputChange(event.target.value);
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
        )}

        {actionEnabled && (
          <Grid
            sx={{ display: "flex", alignItems: "center", marginTop: "4px" }}
            container
            spacing={0.5}
          >
            {((direction === "left" && hasLeftActions) ||
              (direction === "right" && hasRightActions)) && <Grid item>Actions:&nbsp;</Grid>}

            {(actionRequired?.defaultView === "both" ||
              (direction === "left" && actionRequired?.defaultView === "left") ||
              (direction === "right" && actionRequired?.defaultView === "right")) && (
              <Grid item>
                <Button
                  sx={{ fontSize: "12px", textTransform: "initial" }}
                  variant="text"
                  onClick={handleSetDefaultList}
                  disabled={loading}
                >
                  DEFAULT VIEW
                </Button>
              </Grid>
            )}

            {(actionRequired?.selectAll === "both" ||
              (direction === "left" && actionRequired?.selectAll === "left") ||
              (direction === "right" && actionRequired?.selectAll === "right")) && (
              <Grid item>
                <Button
                  sx={{ fontSize: "12px", textTransform: "initial" }}
                  variant="text"
                  onClick={() => handleSelectAll(direction)}
                  disabled={selectAllDisabled || loading}
                >
                  SELECT ALL
                </Button>
              </Grid>
            )}

            {(actionRequired?.clearSelected === "both" ||
              (direction === "left" && actionRequired?.clearSelected === "left") ||
              (direction === "right" && actionRequired?.clearSelected === "right")) && (
              <Grid item>
                <Button
                  sx={{ fontSize: "12px", textTransform: "initial" }}
                  variant="text"
                  onClick={() => handleClearCheck(direction)}
                  disabled={clearSelectDisabled || loading}
                >
                  CLEAR SELECTED
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!isAsyncData) return;
    if (defaultLeft && defaultLeft.length > 0) {
      setLeft(defaultLeft);
    }
  }, [isAsyncData, defaultLeft]);

  useEffect(() => {
    if (!isAsyncData) return;
    if (defaultRight && defaultRight.length > 0) {
      setRight(defaultRight);
    }
  }, [isAsyncData, defaultRight]);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
      <Grid item xs={5}>
        <Text bold color="black" size="text">
          {leftText} {`(${left?.length}/${defaultListLength})`}
        </Text>

        {customList({
          direction: "left",
          items: left,
          nameActionEnabled: !columnViewDisabled,
          actionEnabled: true,
        })}
      </Grid>

      <Grid
        sx={{
          alignSelf: "center",
        }}
        item
        xs={2}
      >
        <Grid container direction="column" alignItems="center">
          {!allCheckDisabled && (
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0 || loading}
              aria-label="move all right"
            >
              {/* ≫ */}
              <KeyboardDoubleArrowRightIcon />
            </Button>
          )}

          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || loading}
            aria-label="move selected right"
          >
            {/* &gt; */}
            <KeyboardArrowRightIcon />
          </Button>

          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || loading}
            aria-label="move selected left"
          >
            {/* &lt; */}
            <KeyboardArrowLeftIcon />
          </Button>

          {!allCheckDisabled && (
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0 || loading}
              aria-label="move all left"
            >
              {/* ≪ */}
              <KeyboardDoubleArrowLeftIcon />
            </Button>
          )}

          {!orderCheckDisabled && (
            <Box
              sx={{
                margin: "8px 0",
                display: "flex",
                flexFlow: "column",
              }}
            >
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleOrderUp}
                disabled={!canRightCheckedOrder || !canRightCheckedOrderUp || loading}
                aria-label="move up"
              >
                {/* ↑ */}
                <KeyboardArrowUpIcon />
              </Button>

              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleOrderDown}
                disabled={!canRightCheckedOrder || !canRightCheckedOrderDown || loading}
                aria-label="move down"
              >
                {/* ↓ */}
                <KeyboardArrowDownIcon />
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <Text bold color="black" size="text">
          {rightText} {`(${right?.length}/${defaultListLength})`}
        </Text>

        {customList({
          direction: "right",
          items: right,
          nameActionEnabled: false,
          actionEnabled: true,
        })}
      </Grid>
    </Grid>
  );
}
