/* eslint-disable no-restricted-syntax */
import { REGULAR_COUNTRIES } from "constants/global";
import {
  QUALITY_TESTLEVEL_CHECKBOX_OPTIONS,
  REGULAR_TESTLEVEL_CHECKBOX_OPTIONS,
  ORDERED_COUNTRIES,
} from "constants/sku-grouping";
import { TEST_LEVEL_QUALITY_LIST, TEST_LEVEL_REGULAR_LIST } from "constants/test-item";
import {
  orderBy as _orderBy,
  uniqBy as _uniqBy,
  entries as _entries,
  fromPairs as _fromPairs,
  some as _some,
  find as _find,
  groupBy as _groupBy,
  has as _has,
  sortBy as _sortBy,
} from "lodash";
import randomColor from "randomcolor";
import { Country, FetchOption, Order } from "types/global";
import {
  ApplicableMarketTestLevel,
  ApplicableTestLevel,
  CombinedTaskByWicNumber,
  SkuGroupMap,
  SkuGroupMappingSku,
  SkuGroupMappingSkuTestLevel,
  SkuGroupMappingTable,
  PrimeTestLevelCheckboxState,
  TestingLevelState,
  TestLevelOption,
  GroupingFilter,
  PrimeTestLevelMarketCheckboxState,
  SkuGroupingFilters,
  SkuMarketTestLevelOptionStatus,
  TestLevelOptionStatus,
} from "types/sku-group";
import {
  CombinedTaskMarketAndTestLevel,
  SelectedTestLevel,
  SkuTaskDisableIds,
  SkuTestLevel,
  SkuTestLevelGroup,
  Task,
} from "types/task";
import { TestLevel } from "types/test-item";
import { deepClone } from "utils";
import { sortedExportMarket } from "utils/filterExportMarket";

const getSkuTaskFilterValue = ({
  tasks,
  editMode = false,
}: {
  tasks: Task[];
  editMode?: boolean;
}): Record<string, string[]> => {
  const results: Record<string, string[]> = {
    deptNum: [],
    vendorNum: [],
    exportMarket: [],
    concept: [],
    isPrimeSku: [],
  };

  if (!tasks?.length) return results;

  const { deptNum, vendorNum, concept } = tasks[0];

  const markets = editMode
    ? getUniqueSelectedExportMarkets(tasks)
    : getUniqueTaskExportMarkets(tasks);

  results.exportMarket = markets;
  results.deptNum = [deptNum as string];
  results.vendorNum = [vendorNum as string];
  results.concept = [concept as string];
  results.isPrimeSku = [];

  return results;
};

const isMatchCountryType = (selectedTasks: Task[]) => {
  const selectedCountries: Country[] = selectedTasks?.map(
    (item: Task) => item?.exportMarket as Country,
  );

  const hasQC = selectedCountries?.some((country) => country === Country.QC);
  const hasRegular = selectedCountries?.some(
    (item) =>
      item === Country.EU || item === Country.UK || item === Country.US || item === Country.GLB,
  );

  const isQualityGrouping = hasQC && !hasRegular;
  const isRegularGoruping = hasRegular && !hasQC;

  return {
    isQualityGrouping,
    isRegularGoruping,
    isValidGrouping: isQualityGrouping || isRegularGoruping,
  };
};

const checkGroupingMatchFilterValues = (selectedTasks: any[]) => {
  const hasSingleTask = selectedTasks.length === 1;
  const hasMultipleTask = selectedTasks.length > 1;

  const checkIsMatchFilterValue = (filterKey: keyof Record<string, string[]>) => {
    const defaultSelectedTaskValue = selectedTasks?.length ? selectedTasks[0][filterKey] : "";

    const isEveryMatched = selectedTasks?.every((task: any) => {
      return task[filterKey] === defaultSelectedTaskValue;
    });

    return isEveryMatched;
  };

  // const isValidDept = checkIsMatchFilterValue("deptNum");
  const isValidVendorNum = checkIsMatchFilterValue("vendorNum");
  const isValidExportMarket = isMatchCountryType(selectedTasks)?.isValidGrouping;
  // const isValidConcept = checkIsMatchFilterValue("concept");

  return {
    filter: {
      // isValidDept,
      isValidVendorNum,
      isValidExportMarket,
      // isValidConcept,
    },
    hasSingleTask,
    hasMultipleTask,
    isMatchFilterValues: hasSingleTask || (isValidVendorNum && isValidExportMarket),
    // isMatchFilterValues:
    //   hasSingleTask || (isValidDept && isValidVendorNum && isValidExportMarket && isValidConcept),
  };
};

const checkTestLevelTaskNotDone = (selectedTasks: Task[]) => {
  const notDoneQualityTask = selectedTasks?.filter((t) =>
    t?.skuTestLevels?.some(
      (s) => [TestLevel.Q, TestLevel.C].includes(s.testLevel as TestLevel) && !s.isCompleted,
    ),
  );

  const notDoneNormalTask = selectedTasks?.filter((t) =>
    t?.skuTestLevels?.some(
      (s) =>
        [TestLevel.A, TestLevel.F, TestLevel.L, TestLevel.P].includes(s.testLevel as TestLevel) &&
        !s.isCompleted,
    ),
  );

  return {
    notDoneQualityTask,
    notDoneNormalTask,
    hasBothSomeDoneTask: !notDoneQualityTask && !notDoneNormalTask,
  };
};

const checkCreateSkuGrouping = (selectedTasks: Task[]) => {
  const { isMatchFilterValues, hasMultipleTask } = checkGroupingMatchFilterValues(selectedTasks);

  const { hasBothSomeDoneTask } = checkTestLevelTaskNotDone(selectedTasks);

  const isTestLevelError = !!hasBothSomeDoneTask;

  const isGroupingError = !isMatchFilterValues;

  const { isQualityGrouping, isRegularGoruping } = isMatchCountryType(selectedTasks);

  return {
    isGroupingError,
    isTestLevelError,
    isQualityGrouping,
    isRegularGoruping,
  };
};

function sortTaskByCurrentTask(tasks: Task[], order: Order): Task[] {
  return tasks?.sort((a: Task, b: Task) => {
    const itemA = a?.isCurrent as boolean;
    const itemB = b?.isCurrent as boolean;

    const isAsc = order === "asc";

    const ascSort = itemA === itemB ? 0 : itemA ? -1 : 1;
    const descSort = itemA === itemB ? 0 : itemB ? -1 : 1;

    return isAsc || !order ? ascSort : descSort;
  });
}

const filterSkuGrouping = (
  selectedGroups: SkuTestLevelGroup[],
  filters: GroupingFilter,
  keyword: string,
) => {
  function filterKeyword(selectedFilters: SkuTestLevelGroup["filters"]) {
    return selectedFilters?.wicNumber?.toLowerCase()?.includes(keyword?.toLowerCase() as string);
  }

  function filterColorCode(selectedFilters: SkuTestLevelGroup["filters"]) {
    const filterValue = filters?.colorCode?.toLowerCase() as string;

    return filterValue
      ? (selectedFilters?.colorCode?.toLowerCase() as string) === filterValue
      : true;
  }

  function filterSizeCode(selectedFilters: SkuTestLevelGroup["filters"]) {
    const filterValue = filters?.sizeCode?.toLowerCase() as string;

    return filterValue
      ? (selectedFilters?.sizeCode?.toLowerCase() as string) === filterValue
      : true;
  }

  function combinedFilters(selectedFilters: SkuTestLevelGroup["filters"]) {
    return (
      filterKeyword(selectedFilters) &&
      filterColorCode(selectedFilters) &&
      filterSizeCode(selectedFilters)
    );
  }

  const { sort, ...selectFilter } = filters;

  const hasSomeFilters = Object.values(selectFilter)?.some((f) => f);

  const filtered =
    hasSomeFilters || keyword
      ? selectedGroups?.filter((group) => combinedFilters(group?.filters))
      : selectedGroups;

  // const filtered =
  //   hasSomeFilters || keyword
  //     ? selectedGroups?.filter((group) => combinedFilters(group?.currentTask))
  //     : selectedGroups;

  const filteredSelectedGroups = (
    !sort
      ? _orderBy(filtered, ["isCurrent"], ["asc"])
      : _orderBy(filtered, ["filters.wicNumber"], [sort])
  ) as SkuTestLevelGroup[];

  const filteredSelectedGroupsWicNumbers = filteredSelectedGroups.map(
    (group) => group.filters?.wicNumber,
  );

  return {
    filteredSelectedGroups,
    filteredSelectedGroupsWicNumbers,
  };
};

/**
 * @Desc use in PrimSkuGroupingPopup to set rows disabled
 * @param selectedSkuTasks
 * @returns SkuTaskDisableIds
 */
function getSelectedSkuDisabledIds(selectedSkuTasks: Task[], editMode: boolean): SkuTaskDisableIds {
  const results = selectedSkuTasks?.reduce(
    (acc: SkuTaskDisableIds, cur) => {
      const isPrimeSku = cur?.isPrimeSku ?? false;
      const isCurrent = cur?.isCurrent ?? false;

      // check referToTaskId matches with task id
      const isCurrentPrimeAndHasCurrentTaskCompleted =
        isPrimeSku &&
        isCurrent &&
        cur?.skuTestLevels?.some((item) => item.referToTaskId === cur?.id && item?.isCompleted);

      const isNotCurrentPrime = (isPrimeSku && !isCurrent) ?? false;

      const isPemSkuAndNotCurrent = (!isPrimeSku && !isCurrent) ?? false;

      const isCurrentAndDeleted = (isCurrent && cur?.isDeleted) ?? false;

      if (editMode && (isCurrentAndDeleted || isPemSkuAndNotCurrent || isCurrent)) {
        acc.disableRemovals.push(cur?.id as number);
      }

      if (
        (editMode && isPemSkuAndNotCurrent) ||
        isCurrentAndDeleted ||
        isNotCurrentPrime ||
        isCurrentPrimeAndHasCurrentTaskCompleted
      ) {
        acc.disableSelections.push(cur?.id as number);
      }

      return acc;
    },
    { disableRemovals: [], disableSelections: [] },
  );

  return {
    disableRemovals: results.disableRemovals?.filter((v, i, a) => a?.indexOf(v) === i),
    disableSelections: results.disableSelections?.filter((v, i, a) => a?.indexOf(v) === i),
  };
}

const getExistingSkuTaskDisabled = (selectedSkuTasks: Task[]) => {
  const selectedTaskIds = selectedSkuTasks?.map((item) => item?.id);

  return selectedSkuTasks?.reduce((acc: number[], cur) => {
    const isAddedInSelectedPrime = selectedTaskIds?.includes(cur?.id);

    if (isAddedInSelectedPrime) {
      acc.push(cur?.id as number);
    }

    return acc;
  }, []);
};

/**
 * @Desc use in PrimSkuGroupingPopup to set selected rows
 * @param groupingSkuData
 * @returns Record<string, boolean>
 */
const getExistingPrimeTaskStates = (groupingSkuData: Task[], checkedSelectedRowIds?: number[]) => {
  return groupingSkuData?.reduce((acc: Record<string, boolean>, cur, index) => {
    if (cur?.isPrimeSku || cur?.isDeleted) {
      const isCurrentSkuPartiallyDone =
        cur?.isCurrent && cur?.skuTestLevels?.some((testLevel) => testLevel?.isCompleted);

      acc[index] = isCurrentSkuPartiallyDone
        ? true
        : !cur?.isDeleted && cur?.isCurrent
        ? false
        : (checkedSelectedRowIds?.includes(cur?.id as number) as boolean);
    }

    return acc || {};
  }, {});
};

const getExistingSelectedRowTaskIds = (groupingSkuData: Task[], editMode: boolean): number[] => {
  return groupingSkuData
    ?.filter((item, index) => {
      const isCurrentSkuPartiallyDone =
        item?.isCurrent && item?.skuTestLevels?.some((testLevel) => testLevel?.isCompleted);

      const isCurrentTask = item?.isCurrent;
      const isPrimeTask = item?.isPrimeSku;
      const isSelectAsPrimeTask = item?.isSelectAsPrime;

      return (
        isSelectAsPrimeTask ||
        isPrimeTask ||
        (isPrimeTask && isCurrentSkuPartiallyDone) ||
        (isPrimeTask && isCurrentTask) ||
        false
      );
    })
    ?.map((item) => item?.id) as number[];
};

const checkAllSkuGroupsCompleted = (groupingSkuData: Task[], isQualityFlow: boolean) => {
  const isAllTestLevelDone = (task: Task, check: boolean) =>
    check && task?.skuTestLevels?.every((testLevel) => testLevel?.isCompleted);

  return (
    groupingSkuData?.every((data) =>
      isQualityFlow
        ? isAllTestLevelDone(data, data?.isQaCompleted as boolean)
        : isAllTestLevelDone(data, data?.isAFLPCompleted as boolean) || false,
    ) || false
  );
};

const getDefaultTestLevelList = (isQualityFlow: boolean) =>
  isQualityFlow ? TEST_LEVEL_QUALITY_LIST : TEST_LEVEL_REGULAR_LIST;

const checkQualityFlow = (exportMarket: string) => exportMarket === Country.QC;

const mapExistingTestLevels = ({ isQualityFlow, task }: { isQualityFlow: boolean; task: Task }) => {
  const defaultTestLevels = isQualityFlow ? TEST_LEVEL_QUALITY_LIST : TEST_LEVEL_REGULAR_LIST;

  const isMatchedLength = task?.skuTestLevels?.length === defaultTestLevels?.length;

  const updatedMissingTestLevels: SkuTestLevel[] = [];

  // check test level is missing. If yes, patch it & set as isNotApplicable -> TRUE
  for (let testLevelIndex = 0; testLevelIndex < defaultTestLevels?.length; testLevelIndex++) {
    const testLevelType = defaultTestLevels[testLevelIndex];

    const currentTestLevel = task?.skuTestLevels?.filter(
      (test) => test?.testLevel === testLevelType,
    )[0];

    const notApplicableTestLevel = {
      isNotApplicable: true,
      isCompleted:
        task?.skuTestLevels?.find((item) => item?.testLevel === testLevelType)?.isCompleted ||
        false,
      isPrimeSku: task?.isPrimeSku,
      referToTaskId: task?.id as number,
      taskId: task?.id as number,
      testLevel: testLevelType,
      wicNumber: task?.wicNumber,
      taskNumber: null,
      taskStatus: null,
      cwItemDescription: null,
      labName: null,
    };

    updatedMissingTestLevels[testLevelIndex] = currentTestLevel || notApplicableTestLevel;
  }

  const listToBeSorted = isMatchedLength ? task?.skuTestLevels : updatedMissingTestLevels;

  const sortedTestLevels = [...listToBeSorted]?.sort((a, b) => {
    const testLevelA: SkuTestLevel | string = a?.testLevel || "";
    const testLevelB: SkuTestLevel | string = b?.testLevel || "";

    // asc order
    const defaultOrder = testLevelA > testLevelB ? 1 : testLevelA < testLevelB ? -1 : 0;

    // desc order
    const qualityOrder = testLevelA < testLevelB ? 1 : testLevelA > testLevelB ? -1 : 0;

    return isQualityFlow ? qualityOrder : defaultOrder;
  });

  return sortedTestLevels;
};

const combineGroupingListData = (listData: Task[]) => {
  const taskIdsByWicNumber: CombinedTaskByWicNumber = {};

  //* patch property combinedMarketAndTestLevels for listData
  const patchedListData =
    listData?.map((listItem) => ({
      ...listItem,
      combinedMarketAndTestLevels: [],
    })) || [];

  //* convert list data
  const list = patchedListData?.reduce((acc: Task[], cur: Task) => {
    //* check wic number if store in acc & return index
    const targetIndex = acc?.findIndex((val) => val?.wicNumber === cur?.wicNumber);

    // const masterWicId = cur.masterWicId as number;
    const wicNumber = cur.wicNumber as string;
    const taskId = cur.id as number;
    const exportMarket = cur.exportMarket as string;
    const skuTestLevels = cur.skuTestLevels as SkuTestLevel[];
    const isPrimeSku = cur?.isPrimeSku as boolean;

    const isRegularTask = REGULAR_COUNTRIES?.includes(exportMarket as Country);

    const TEST_LEVEL_LIST = isRegularTask ? TEST_LEVEL_REGULAR_LIST : TEST_LEVEL_QUALITY_LIST;

    const patchedTestLevels = TEST_LEVEL_LIST?.reduce((patchAcc: SkuTestLevel[], testLevelCur) => {
      const currentTestLevel = _find(skuTestLevels, { testLevel: testLevelCur }) as SkuTestLevel;

      const notApplicableTestLevel = {
        testLevel: testLevelCur,
        wicNumber: cur?.wicNumber,
        taskId,
        referToTaskId: taskId,
        isNotApplicable: true,
        isPrimeSku: true,
        isCompleted: false,
        isCurrent: false,
        taskNumber: null,
        taskStatus: null,
        cwItemDescription: null,
        labName: null,
      };

      patchAcc.push(currentTestLevel || notApplicableTestLevel);

      return patchAcc;
    }, []);

    const targetCombinedMarketAndTestLevels =
      (acc[targetIndex]?.combinedMarketAndTestLevels as CombinedTaskMarketAndTestLevel[]) ?? [];

    const currentMarketAndTestLevels = {
      ...cur,
      skuTestLevels: patchedTestLevels,
      taskId,
      exportMarket,
      isPrimeSku,
    };

    if (targetIndex === -1) {
      // set 1st market & test level if task hasnt stored
      cur.combinedMarketAndTestLevels = [currentMarketAndTestLevels];
      acc.push(cur);

      // set id to new wic number
      taskIdsByWicNumber[wicNumber] = [taskId];
    } else {
      // set current market & test level to existing task
      acc[targetIndex].combinedMarketAndTestLevels = [
        ...targetCombinedMarketAndTestLevels,
        currentMarketAndTestLevels,
      ];

      // set id to existing wic number
      taskIdsByWicNumber[wicNumber] = [...taskIdsByWicNumber[wicNumber], taskId];
    }

    return acc;
  }, []);

  return { list: _orderBy(list, ["isCurrent"], ["asc"]) as Task[], taskIdsByWicNumber };
};

const getUniqueSelectedExportMarkets = (selectedTasks: Task[]) => {
  const countryList = selectedTasks?.reduce((acc: string[], cur) => {
    cur?.combinedMarketAndTestLevels?.forEach((combined) => {
      acc.push(combined?.exportMarket as string);
    });

    return acc;
  }, []);

  return countryList?.filter((val, index, arr) => arr.indexOf(val) === index) as string[];
};

const getUniqueTaskExportMarkets = (selectedTasks: Task[]) => {
  const countryList = selectedTasks?.reduce((acc: string[], cur) => {
    if (!acc?.includes(cur?.exportMarket as string)) {
      acc.push(cur?.exportMarket as string);
    }

    return acc;
  }, []);

  return countryList?.filter((val, index, arr) => arr.indexOf(val) === index) as string[];
};

const getExportMarketCombinations = (data: Task[]): string[] => {
  const combinedMarketList = data?.reduce((acc: string[], cur: Task) => {
    if (cur?.combinedMarketAndTestLevels?.length) {
      const mapped = cur?.combinedMarketAndTestLevels?.map((item) => item?.exportMarket);

      const uniqueMapped = [...new Set(mapped)];

      acc.push(uniqueMapped?.toString());
    }

    return acc;
  }, []);

  const uniqueCombinedMarkets = [...new Set(combinedMarketList)];

  return uniqueCombinedMarkets;
};

const orderApplicableTestLevel = (arr: Partial<SkuTestLevel>[], customOrder: TestLevel[]) => {
  const orderForIndexVals = customOrder.slice(0).reverse();

  return arr.sort((a, b) => {
    const aIndex: number = -orderForIndexVals.indexOf(a?.testLevel as TestLevel);
    const bIndex: number = -orderForIndexVals.indexOf(b?.testLevel as TestLevel);
    return aIndex - bIndex;
  });
};

const mergeApplicableTestLevelsByMarket = ({
  editMode,
  applicableTestLevel,
  // skuTestLevels,
  task,
}: {
  editMode: boolean;
  applicableTestLevel: ApplicableTestLevel;
  task: Task;
  // skuTestLevels: SkuTestLevel[];
}): SkuTestLevel[] => {
  const exportMarket = task?.exportMarket as Country;

  const isQC = exportMarket === Country.QC;

  const testLevelList = isQC ? TEST_LEVEL_QUALITY_LIST : TEST_LEVEL_REGULAR_LIST;

  const targetApplicableTestLevel = applicableTestLevel[exportMarket];

  const isExistingPrime = task.isPrimeSku;

  const taskTestLevels = task.skuTestLevels;

  const updated = testLevelList?.reduce(
    (acc: Partial<SkuTestLevel>[], testLevel: TestLevel, index) => {
      const isNotApplicableTestLevel = !targetApplicableTestLevel[
        testLevel as keyof Partial<ApplicableMarketTestLevel>
      ] as boolean;

      const targetTaskSkuTestLevel = taskTestLevels?.find(
        (taskSkuTestLevel) => taskSkuTestLevel?.testLevel === testLevel,
      );

      // edit mode -> use isNotApplicable in existing sku test level for pen sku
      const isNotApplicable = editMode
        ? targetTaskSkuTestLevel?.isNotApplicable
        : isNotApplicableTestLevel;

      const taskTestLevel = taskTestLevels[index];

      // new sku does not have skuTestLevels
      if (!taskTestLevel) {
        acc.push({
          testLevel,
          isNotApplicable,
          // isPrimeSku:
          //   skuTestLevels?.find((item) => item?.testLevel === testLevel)?.isPrimeSku ??
          //   false,
        });
      } else if (isExistingPrime) {
        acc.push(taskTestLevel);
      } else {
        acc.push({
          ...taskTestLevel,
          isNotApplicable,
        });
      }

      return orderApplicableTestLevel(acc, testLevelList);
    },
    [],
  );

  return updated as SkuTestLevel[];
};

const mapSkusWithApplicableTestLevel = ({
  editMode,
  groupingSkuData,
  applicableTestLevel,
  testMatrix,
}: {
  editMode: boolean;
  groupingSkuData: Task[];
  applicableTestLevel: ApplicableTestLevel;
  testMatrix: {
    brand: string;
    subCategory: string;
  };
}) => {
  const cloneGroupingSkuData = deepClone(groupingSkuData) as Task[];

  const updated = cloneGroupingSkuData?.reduce((acc: Task[], cur: Task) => {
    const isValidCreateGroupingNewSku = !cur?.isPrimeSku && !editMode;

    const isValidEditGroupingPenSku =
      editMode &&
      !cur?.isPrimeSku &&
      cur?.brand === testMatrix?.brand &&
      cur?.subCategory === testMatrix?.subCategory;

    if (isValidCreateGroupingNewSku || isValidEditGroupingPenSku) {
      //* fill user selected test matrix into new sku
      if (isValidCreateGroupingNewSku) {
        cur.brand = testMatrix?.brand;
        cur.subCategory = testMatrix?.subCategory;
      }

      const insertedCombinedMarketAndTestLevels = cur?.combinedMarketAndTestLevels?.map(
        (combined) => {
          const targetApplicableTestLevels = mergeApplicableTestLevelsByMarket({
            editMode,
            applicableTestLevel,
            task: cur,
            // skuTestLevels: combined.skuTestLevels,
          });

          return {
            ...combined,
            skuTestLevels: targetApplicableTestLevels,
          };
        },
      ) as Task["combinedMarketAndTestLevels"];

      cur.combinedMarketAndTestLevels = insertedCombinedMarketAndTestLevels;
    }

    acc.push(cur);

    return acc;
  }, []);

  return updated;
};

const checkIsValidTestMatrix = ({
  country,
  brand,
  subCategory,
}: {
  country?: Country | null;
  brand?: string | null;
  subCategory?: string | null;
}): boolean => !!(country && brand && subCategory) ?? false;

const checkIsSelectPrimeSubmitDisabled = ({
  selectedGroupingPrimeSkus,
}: {
  selectedGroupingPrimeSkus?: Task[];
}) => {
  return (
    (!selectedGroupingPrimeSkus?.length &&
      !selectedGroupingPrimeSkus?.filter((selected) => !selected?.isDeleted)?.length) ??
    false
  );
};

const groupByCombinedTaskSkuTestLevel = ({ skuTestLevels }: { skuTestLevels: SkuTestLevel[] }) => {
  const cloneSkuTestLevels = deepClone(skuTestLevels) as SkuTestLevel[];

  return cloneSkuTestLevels?.reduce((acc: SkuGroupMappingSkuTestLevel, cur) => {
    const testLevel = cur?.testLevel as string;

    if (!acc.testLevel) {
      acc[testLevel] = cur;
    }

    return acc;
  }, {});
};

const convertTasksToSkuGroupMappingTable = ({
  editMode,
  isDeletedGrouping,
  tasks,
  applicableTestLevel,
  testMatrix,
  selectedPrimeSkuWicNumbers,
}: {
  editMode: boolean;
  isDeletedGrouping: boolean;
  tasks: Task[];
  applicableTestLevel: ApplicableTestLevel;
  testMatrix: {
    brand: string;
    subCategory: string;
  };
  selectedPrimeSkuWicNumbers: string[];
}): SkuGroupMappingTable => {
  if (!tasks?.length || !testMatrix?.brand || !testMatrix?.subCategory) return {};

  const cloneTasks = deepClone(tasks) as Task[];

  const mappingTable = cloneTasks.reduce((acc: SkuGroupMappingTable, task) => {
    const wicNumber = task.wicNumber as string;

    const currentCombinedMarketAndTestLevels =
      task?.combinedMarketAndTestLevels as CombinedTaskMarketAndTestLevel[];

    // get sku by market from combinedMarketAndTestLevels
    for (let i = 0; i < currentCombinedMarketAndTestLevels.length; i++) {
      //* extract default task data
      const { taskId, combinedMarketAndTestLevels, ...combinedTask } =
        currentCombinedMarketAndTestLevels[i];

      const market = combinedTask.exportMarket as string;

      //* map api applicable data
      const mappedApplicableTestLevels = mergeApplicableTestLevelsByMarket({
        editMode,
        applicableTestLevel,
        task: combinedTask,
        // skuTestLevels: combinedTask.skuTestLevels,
      });

      const groupedTestLevel = groupByCombinedTaskSkuTestLevel({
        skuTestLevels: mappedApplicableTestLevels,
      });

      if (acc[wicNumber]) {
        //* if sku exists, assign task in markets property
        acc[wicNumber].markets[market] = groupedTestLevel;
      } else {
        //* set isSelectedAsPrime if exists & not existing prime
        const isSelectedAsPrime =
          editMode || (!editMode && !combinedTask?.isPrimeSku)
            ? selectedPrimeSkuWicNumbers?.includes(combinedTask?.wicNumber as string)
            : false;

        //* if sku does not exist, set default properties
        acc[wicNumber] = {
          //* for lookup
          color: combinedTask?.isPrimeSku || isSelectedAsPrime ? randomColor() ?? "" : "",
          wicNumber: combinedTask?.wicNumber ?? "",
          colorCode: combinedTask?.colorCode ?? "",
          sizeCode: combinedTask?.sizeCode ?? "",
          cwItemDescription: combinedTask?.cwItemDescription ?? "",
          //* for checking
          isCurrent: combinedTask?.isCurrent ?? false,
          isExistingPrime: combinedTask?.isPrimeSku ?? false,
          isSelectedAsPrime: isSelectedAsPrime ?? false,
          isDeleted: !!(combinedTask?.isCurrent && isDeletedGrouping) ?? false,
          isMultipleMarkets: currentCombinedMarketAndTestLevels?.length > 1 ?? false,
          markets: {
            [market]: groupedTestLevel,
          },
          originalTask: task,
        };
      }
    }

    return acc;
  }, {});

  return mappingTable;
};

//* convert mapping table to sku selected groups
const convertMappingTableToSelectedGroups = ({
  isDeletedGrouping,
  editMode,
  skuGroupMappingTable,
}: {
  isDeletedGrouping: boolean;
  editMode: boolean;
  skuGroupMappingTable: SkuGroupMappingTable;
}): SkuTestLevelGroup[] => {
  const currentPrimeSkuFiltered = Object.entries(skuGroupMappingTable)?.find((mapping) => {
    const skuGroupMappingSku: SkuGroupMappingSku = mapping?.[1];

    return skuGroupMappingSku.isCurrent;
  });

  const currentPrimeSku = currentPrimeSkuFiltered?.[1] as SkuGroupMappingSku;

  const testLevelsGroups: SkuTestLevelGroup[] = Object.entries(skuGroupMappingTable)?.reduce(
    (acc: SkuTestLevelGroup[], [, mappingSku], _index, mappingSkuEntries) => {
      const wicNumber = mappingSku?.wicNumber;
      const isCurrent = mappingSku?.isCurrent;
      const isSelectedAsPrime = mappingSku?.isSelectedAsPrime;
      const isExistingPrime = mappingSku?.isExistingPrime;

      const mappingSkuMarketEntries: [string, SkuGroupMappingSkuTestLevel][] = Object.entries(
        mappingSku?.markets,
      );
      const mappingSkuMarketTestLevelEntries = Object.entries(
        mappingSkuMarketEntries?.[0]?.[1] as SkuGroupMappingSkuTestLevel,
      );

      const selectedTestLevels: SelectedTestLevel[] = mappingSkuMarketTestLevelEntries?.map(
        (mappingSkuMarketTestLevelEntry) => {
          const mappingTestLevel = mappingSkuMarketTestLevelEntry?.[0] as TestLevel;

          const mappingSkuTestLevel = mappingSkuMarketTestLevelEntry?.[1] as SkuTestLevel;

          const isNotApplicableAllMarkets = mappingSkuMarketEntries.every((entry) => {
            return !!entry[1][mappingTestLevel]?.isNotApplicable;
          });

          const isTestLevelMatchCurrentSku =
            currentPrimeSku?.wicNumber === mappingSkuTestLevel?.wicNumber;

          const isTestLevelMatchMappingSku = wicNumber === mappingSkuTestLevel?.wicNumber;

          const isExisingPrimeTestLevel = isExistingPrime && isTestLevelMatchMappingSku;

          const isSelectedAsPrimeAndNotHaveWicNumber =
            !mappingSkuTestLevel?.wicNumber && isSelectedAsPrime; // for test level sku deleted in current selected as prime sku

          const isPrimeInCreateMode = !editMode && (isSelectedAsPrime || isExisingPrimeTestLevel);

          const isPrimeInEditMode =
            editMode &&
            isCurrent &&
            isSelectedAsPrime &&
            (isTestLevelMatchMappingSku || isSelectedAsPrimeAndNotHaveWicNumber);

          //* null for NA, undefined for empty
          const calculatedWicNumber =
            isPrimeInCreateMode || isPrimeInEditMode
              ? wicNumber
              : isNotApplicableAllMarkets
              ? null
              : "";

          const isCurrentSkuDeleted = isDeletedGrouping && isTestLevelMatchCurrentSku;

          const isTestLevelMatchCurrentAndNotSelectAsPrime =
            isTestLevelMatchCurrentSku && !currentPrimeSku?.isSelectedAsPrime;

          const defaultTestLevel =
            isCurrentSkuDeleted || isTestLevelMatchCurrentAndNotSelectAsPrime
              ? null
              : {
                  wicNumber: mappingSkuTestLevel?.wicNumber || null,
                  cwItemDescription: mappingSkuTestLevel?.cwItemDescription || null,
                  isCompleted: mappingSkuTestLevel?.isCompleted || false,
                  isPrimeSku: mappingSkuTestLevel?.isPrimeSku || false,
                  isCurrent: isTestLevelMatchCurrentSku || false,
                  isNotApplicable: mappingSkuTestLevel?.isNotApplicable || false,
                };

          const isCompletedDefaultTestLevel = defaultTestLevel?.isCompleted || false;

          //todo: disable current checking
          const isEditingTestLevel =
            // editMode && ((isCurrent && !isCompletedDefaultTestLevel) || isTestLevelMatchCurrentSku);
            editMode;

          return {
            selectedWicNumber: calculatedWicNumber,
            testLevel: mappingTestLevel,
            isEditing: isEditingTestLevel || false,
            defaultTestLevel,
          } as SelectedTestLevel;
        },
      );

      //* access mapping table to get sku flags
      const currentMappingSku = skuGroupMappingTable[wicNumber];

      const currentSelectedGroup: SkuTestLevelGroup = {
        testLevels: selectedTestLevels,
        //* for sorting only
        filters: {
          wicNumber: currentMappingSku?.wicNumber || "",
          colorCode: currentMappingSku?.colorCode || "",
          sizeCode: currentMappingSku?.sizeCode || "",
          isCurrent: currentMappingSku?.isCurrent || false,
          isDeleted: currentMappingSku?.isDeleted || false,
          isExistingPrime: currentMappingSku?.isExistingPrime || false,
          isSelectedAsPrime: currentMappingSku?.isSelectedAsPrime || false,
        },
      };

      acc.push(currentSelectedGroup);

      return acc;
    },
    [],
  );

  return testLevelsGroups;
};

const editSelectAsPrimeSelectedGroups = ({
  checked,
  isDeletedGrouping,
  selectedGroups,
  selectedGroupWicNumber,
}: {
  checked: boolean;
  isDeletedGrouping: boolean;
  selectedGroups: SkuTestLevelGroup[];
  selectedGroupWicNumber: string;
}): SkuTestLevelGroup[] => {
  const clonedSelectedGroups = deepClone(selectedGroups) as SkuTestLevelGroup[];

  clonedSelectedGroups.forEach((group) => {
    // skip if group is deleted
    if (!isDeletedGrouping) {
      // update selected group status, if checked seelcted as prime
      if (group.filters.wicNumber === selectedGroupWicNumber) {
        group.filters.isSelectedAsPrime = checked;
      }

      group.testLevels.forEach((groupTestLevel) => {
        const isMatchSelectedGroupWicNumber = [
          groupTestLevel.selectedWicNumber,
          groupTestLevel.defaultTestLevel?.wicNumber,
        ].includes(selectedGroupWicNumber);

        const isApplicableTestLevel = groupTestLevel.selectedWicNumber !== null;

        // set empty test level in edit mode
        if (
          !checked &&
          groupTestLevel.isEditing &&
          isApplicableTestLevel &&
          isMatchSelectedGroupWicNumber
        ) {
          groupTestLevel.selectedWicNumber = "";
          groupTestLevel.defaultTestLevel = null;
        }
      });
    }
  });

  return clonedSelectedGroups;
};

//* convert mapping table to prime checkbox state
const convertMappingTableToPrimeTestLevelCheckboxes = ({
  editMode,
  isQualityFlow,
  skuGroupMappingTable,
}: {
  editMode: boolean;
  isQualityFlow: boolean;
  skuGroupMappingTable: SkuGroupMappingTable;
}): PrimeTestLevelCheckboxState => {
  const primeSkuTestLevelCheckboxState: PrimeTestLevelCheckboxState = Object.entries(
    skuGroupMappingTable,
  )?.reduce((acc: PrimeTestLevelCheckboxState, mappingSku) => {
    const mappingSkuWicNumber = mappingSku?.[0] as string;

    const { markets, isCurrent, isExistingPrime, isSelectedAsPrime } =
      mappingSku?.[1] as SkuGroupMappingSku;

    const isPrimeSku = checkIsPrimeSku(editMode, isCurrent, isExistingPrime, isSelectedAsPrime);

    if (isPrimeSku) {
      const currentMappingSkuMarkets = markets as SkuGroupMappingSku["markets"];

      const DEFAULT_TEST_LEVEL_OPTIONS = getDefaultTestLevelCheckboxOptions({ isQualityFlow });

      Object.entries(currentMappingSkuMarkets)?.forEach((mappingSkuMarket) => {
        const market = mappingSkuMarket?.[0] as string;
        const skuTestLevelEntries = Object.entries(mappingSkuMarket?.[1]);

        const groupedTestLevels = DEFAULT_TEST_LEVEL_OPTIONS?.reduce(
          (grouped: Partial<TestingLevelState>, cur: TestLevelOption) => {
            const testLevel = cur?.label;

            const currentSkuTestLevel = skuTestLevelEntries?.find((stle) => {
              const entryTestLevel = stle?.[0] as TestLevel;

              return entryTestLevel === testLevel;
            })?.[1] as SkuTestLevel;

            const isNotApplicable = currentSkuTestLevel?.isNotApplicable || false;

            const isCompleted = currentSkuTestLevel?.isCompleted || false;

            const isSelectAsPrimeInCreateMode = !editMode && isSelectedAsPrime;

            const isPrimeTestLevel =
              (currentSkuTestLevel?.isPrimeSku &&
                currentSkuTestLevel?.wicNumber === mappingSkuWicNumber) ||
              false;

            const isCurrentPrimeCompletedTestLevel = isCurrent && isPrimeTestLevel && isCompleted;

            // NA test level, set Null
            // else, set TRUE:
            // selected as prime in create mode OR
            // current & prime match current wicNumber & completed OR
            // prime & match current wicNumber
            if (isNotApplicable) {
              grouped[testLevel] = null;
            } else {
              grouped[testLevel] =
                isSelectAsPrimeInCreateMode || isCurrentPrimeCompletedTestLevel || isPrimeTestLevel;
            }

            return grouped;
          },
          {},
        );

        if (acc[mappingSkuWicNumber]) {
          acc[mappingSkuWicNumber][market] = groupedTestLevels;
        } else {
          acc[mappingSkuWicNumber] = {
            [market]: groupedTestLevels,
          };
        }
      });
    }

    return acc;
  }, {});

  return primeSkuTestLevelCheckboxState;
};

const convertMappingTableToSelectAsPrimeCheckboxes = ({
  skuGroupMappingTable,
}: {
  skuGroupMappingTable: SkuGroupMappingTable;
}) => {
  const selectAsPrimeCheckbox: Record<string, boolean> = _entries(skuGroupMappingTable)?.reduce(
    (acc: Record<string, boolean>, mappingSku) => {
      const isExistingPrime = mappingSku?.[1]?.isExistingPrime;
      const isSelectedAsPrime = mappingSku?.[1]?.isSelectedAsPrime;

      if (isExistingPrime || isSelectedAsPrime) {
        const wicNumber = mappingSku?.[0] as string;

        acc[wicNumber] = true;
      }

      return acc;
    },
    {},
  );

  return selectAsPrimeCheckbox;
};

//* update prime checkbox state
const updatePrimeSkuTestLevelCheckbox = ({
  selectedWicNumber,
  testLevel,
  checked,
  primeSkuTestLevelCheckboxesState,
}: {
  selectedWicNumber: string;
  testLevel: TestLevel;
  checked: boolean | null;
  primeSkuTestLevelCheckboxesState: PrimeTestLevelCheckboxState;
}): PrimeTestLevelCheckboxState => {
  const clonePrimeCheckboxesState = JSON.parse(
    JSON.stringify(primeSkuTestLevelCheckboxesState),
  ) as PrimeTestLevelCheckboxState;

  if (!clonePrimeCheckboxesState[selectedWicNumber]) return clonePrimeCheckboxesState;

  const selectedTestLevel = testLevel as keyof TestingLevelState;

  //* update test level in all markets under the same selected group
  const marketTestLevelEntries = _entries(clonePrimeCheckboxesState[selectedWicNumber])?.map(
    (market) => {
      const currnetMarketTestLevelEntries = _entries(market?.[1])?.map((testLevelEntry) => {
        //* update test level checkbox if macthed & not null (not applicable)
        const isMatchTestLevel = testLevelEntry?.[0] === selectedTestLevel;
        const isApplicableTestLevel = testLevelEntry?.[1] !== null;

        //* use for single market in condition
        // const isMatchMarket = market[0] === exportMarket;

        if (isMatchTestLevel && isApplicableTestLevel) {
          testLevelEntry[1] = checked;
        }

        return testLevelEntry;
      });

      //* update market test level state
      market[1] = _fromPairs(currnetMarketTestLevelEntries) as Partial<TestingLevelState>;

      return market;
    },
  );

  clonePrimeCheckboxesState[selectedWicNumber] = _fromPairs(
    marketTestLevelEntries,
  ) as PrimeTestLevelMarketCheckboxState;

  return clonePrimeCheckboxesState;
};

//* check prime sku market test level is partially NA
const hasNotApplicableSkuTestLevel = ({
  originalTask,
  testLevel,
}: {
  originalTask: Task;
  testLevel: TestLevel;
}): boolean => {
  return originalTask?.combinedMarketAndTestLevels?.some((combined) => {
    return _some(combined?.skuTestLevels, { testLevel: testLevel, isNotApplicable: true });
  }) as boolean;
};

//* look up master wic task by task id
const lookupMasterWicTaskByTaskId = ({
  tasks,
  taskId,
}: {
  tasks: Task[];
  taskId: number;
}): Task => {
  return _find(tasks, { id: taskId }) as Task;
};

const checkGroupTestLevelStatus = ({
  editMode,
  testLevel,
  selectedWicNumber,
  selectedGroupTestLevel,
  skuGroupMappingTable,
}: {
  editMode: boolean;
  testLevel: TestLevel;
  selectedWicNumber: string | null;
  selectedGroupTestLevel: SelectedTestLevel;
  skuGroupMappingTable: SkuGroupMappingTable;
}) => {
  const state = {
    disabled: false,
    isFullyCompleted: false,
    isPartiallyCompleted: false,
    isFullyNotApplicable: false,
    isPartiallyNotApplicable: false,
  };

  //* set disabled, if master wic does not exist
  if (!skuGroupMappingTable[selectedWicNumber as string]) {
    state.disabled = true;

    return state;
  }

  const { isDeleted, isExistingPrime, isCurrent, markets } = skuGroupMappingTable[
    selectedWicNumber as string
  ] as SkuGroupMappingSku;
  const isFullyNotApplicable = _entries(markets)?.every((mkt) => {
    return mkt?.[1]?.[testLevel]?.isNotApplicable ?? false;
  });

  const isPartiallyNotApplicable = _entries(markets)?.some((mkt) => {
    return mkt?.[1]?.[testLevel]?.isNotApplicable ?? false;
  });

  const isFullyCompleted = _entries(markets)?.every((mkt) => {
    const isCompleted = mkt?.[1]?.[testLevel]?.isCompleted;
    const isMatchSelectedWicNumber = mkt?.[1]?.[testLevel]?.wicNumber === selectedWicNumber;

    return isMatchSelectedWicNumber && isCompleted;
  });

  const isPartiallyCompleted = _entries(markets)?.some((mkt) => {
    const isCompleted = mkt?.[1]?.[testLevel]?.isCompleted;
    const isMatchSelectedWicNumber = mkt?.[1]?.[testLevel]?.wicNumber === selectedWicNumber;

    return isMatchSelectedWicNumber && isCompleted;
  });

  //* create mode disabled
  //* 1, existing prime
  //* 2, pim w / fully not applicable
  const disabledInCreateMode =
    !editMode && (isExistingPrime || (!isExistingPrime && isFullyNotApplicable));

  //* edit mode disabled
  //* 1, isDeleted
  //* 2, existing prime w / fully completed
  //* 3, existing prime w / fully NA
  //* 4, not current & ( not editing OR fully completed )
  const disabledInEditMode =
    editMode &&
    (isDeleted ||
      (isExistingPrime && isFullyCompleted) ||
      (isExistingPrime && isFullyNotApplicable) ||
      (!isCurrent && (!selectedGroupTestLevel?.isEditing || isFullyCompleted)) ||
      //todo double confirm this checking
      (isCurrent &&
        !selectedGroupTestLevel?.isEditing &&
        selectedGroupTestLevel?.selectedWicNumber !== "" &&
        selectedGroupTestLevel?.selectedWicNumber === selectedWicNumber));

  if (disabledInCreateMode || disabledInEditMode) {
    state.disabled = true;
  }

  if (isFullyCompleted) {
    state.isFullyCompleted = true;
  } else if (isPartiallyCompleted) {
    state.isPartiallyCompleted = true;
  }

  if (isFullyNotApplicable) {
    state.isFullyNotApplicable = isFullyNotApplicable;
  } else if (isPartiallyNotApplicable) {
    state.isPartiallyNotApplicable = isPartiallyNotApplicable;
  }

  return state;
};

function checkPrimeSkuTestLevelCheckboxStatus({
  editMode,
  testLevel,
  selectedGroup,
  skuGroupMappingTable,
}: {
  editMode: boolean;
  testLevel: TestLevel;
  selectedGroup: SkuTestLevelGroup;
  skuGroupMappingTable: SkuGroupMappingTable;
}) {
  const state = {
    testLevelDisabled: false,
  };

  const currentGroupSelctedWicNumber = selectedGroup?.filters.wicNumber;

  //* get selected group test level sku master wic id
  const currentSelectedGroupTestLevel = selectedGroup?.testLevels?.filter(
    (sg) => sg?.testLevel === testLevel,
  )[0];

  const currentGroupTestLevelWicNumber = (currentSelectedGroupTestLevel?.selectedWicNumber ||
    currentSelectedGroupTestLevel?.defaultTestLevel?.wicNumber) as string;

  //* access mapping table w/ test level wicNumber to check status
  const { isExistingPrime, isDeleted, isCurrent, isSelectedAsPrime, markets } =
    skuGroupMappingTable[currentGroupSelctedWicNumber];

  //* return default state if undefined
  if (!skuGroupMappingTable[currentGroupSelctedWicNumber]) return state;

  //* check test level complete & NA status w/ test level wicNumber
  const { isFullyCompleted, isFullyNotApplicable } = checkGroupTestLevelStatus({
    editMode,
    testLevel,
    selectedWicNumber: currentGroupTestLevelWicNumber,
    selectedGroupTestLevel: currentSelectedGroupTestLevel,
    skuGroupMappingTable,
  });

  // assume there is only 1 market in edit mode
  const isCompletedPimSkuTestLevelInEditMode =
    editMode &&
    Object.values(markets)[0][testLevel]?.wicNumber !== currentGroupSelctedWicNumber &&
    isFullyCompleted;

  //* create mode
  //* 1, is existing prime
  //* 2, not fully NA
  const disabledInCreateMode = !editMode && !isSelectedAsPrime && isExistingPrime;

  //* edit mode with 1 of the following conditions:
  //* 1, deleted
  //* 2, not isCurrent && existing prime & not editing
  //* 3, isCurrent && existing prime & pim sku test level by default
  //* 4, current & fully completed
  const disabledInEditMode =
    editMode &&
    (isDeleted ||
      (!isCurrent && isExistingPrime && !currentSelectedGroupTestLevel?.isEditing) ||
      (isCurrent && isExistingPrime && isCompletedPimSkuTestLevelInEditMode) ||
      (isCurrent && isFullyCompleted));

  //* apply in both mode
  //* 1, is fully not applicable
  state.testLevelDisabled = isFullyNotApplicable || disabledInCreateMode || disabledInEditMode;

  return state;
}

//* default sku selected groups sorting
const sortDefaultSelectedGroup = (selectedGroups: SkuTestLevelGroup[]): SkuTestLevelGroup[] => {
  return _orderBy(
    selectedGroups,
    [
      "filters.isDeleted",
      "filters.isCurrent",
      "filters.isSelectedAsPrime",
      "filters.isExistingPrime",
      "filters.wicNumber",
    ],
    ["desc", "desc", "desc", "desc", "asc"],
  ) as SkuTestLevelGroup[];
};

//* get default test level options for prime sku checkbox
export const getDefaultTestLevelCheckboxOptions = ({
  isQualityFlow,
}: {
  isQualityFlow: boolean;
}) => {
  return isQualityFlow ? QUALITY_TESTLEVEL_CHECKBOX_OPTIONS : REGULAR_TESTLEVEL_CHECKBOX_OPTIONS;
};

//* get sku grouping filter options in sku grouping section
const getSkuGroupingFilterOptions =
  ({ tasks }: { tasks: Task[] }) =>
  (filter: keyof Pick<GroupingFilter, "colorCode" | "sizeCode">): FetchOption[] => {
    const mappedFilter = tasks?.map((task: Task) => {
      return {
        name: task[filter] as string,
      };
    });

    return _uniqBy(mappedFilter, "name") as FetchOption[];
  };

//* lookup mapping table sku markets, array or stringify
const lookupMappingSkuMarkets = ({ markets }: { markets: SkuGroupMappingSku["markets"] }) => {
  const marketList = _entries(markets)?.map((market) => market[0]);

  // const list = _sortBy(marketList, [(mkt) => mkt]);
  const list = sortedExportMarket(marketList);

  const stringify = list?.toString() ?? "";

  return { list, stringify };
};

const checkIsFulfillTargetTestLevelMarkets = ({
  targetMarketList,
  primeMarketList,
}: {
  targetMarketList: string[];
  primeMarketList: string[];
}) => {
  return targetMarketList?.every((targetMarket) => primeMarketList?.includes(targetMarket));
};

const checkIsFullyNotApplicableInMarkets = ({
  testLevel,
  markets,
}: {
  testLevel: TestLevel;
  markets: SkuGroupMappingSku["markets"];
}) => {
  return _entries(markets)?.every((marketEntry) => {
    const marketTestLevelEntries = _entries(marketEntry[1]);

    const selectedTestLevelEntry = marketTestLevelEntries?.filter(
      (testLevelEntry) => (testLevelEntry?.[0] as TestLevel) === testLevel,
    )?.[0];

    return selectedTestLevelEntry?.[1]?.isNotApplicable ?? false;
  });
};

const checkIsFullyPrimeInMarkets = ({
  testLevel,
  markets,
}: {
  testLevel: TestLevel;
  markets: SkuGroupMappingSku["markets"];
}) => {
  return _entries(markets)?.every((marketEntry) => {
    const marketTestLevelEntries = _entries(marketEntry[1]);

    const selectedTestLevelEntry = marketTestLevelEntries?.filter(
      (testLevelEntry) => (testLevelEntry?.[0] as TestLevel) === testLevel,
    )?.[0];

    return selectedTestLevelEntry?.[1]?.isPrimeSku ?? false;
  });
};

const checkIsPartiallyPrimeInMarkets = ({
  testLevel,
  markets,
}: {
  testLevel: TestLevel;
  markets: SkuGroupMappingSku["markets"];
}) => {
  return _entries(markets)?.some((marketEntry) => {
    const marketTestLevelEntries = _entries(marketEntry[1]);

    const selectedTestLevelEntry = marketTestLevelEntries?.filter(
      (testLevelEntry) => (testLevelEntry?.[0] as TestLevel) === testLevel,
    )?.[0];

    return selectedTestLevelEntry?.[1]?.isPrimeSku ?? false;
  });
};

const checkIsAllMarketsCheckedPrimeCheckbox = ({
  testLevel,
  wicNumber, // wicNumber in primeSkuTestLevelCheckboxes
  primeSkuTestLevelCheckboxes,
}: {
  testLevel: TestLevel;
  wicNumber: string;
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState;
}) => {
  const selectedPrimeCheckboxStateInMarkets = primeSkuTestLevelCheckboxes?.[
    wicNumber
  ] as PrimeTestLevelMarketCheckboxState;

  return selectedPrimeCheckboxStateInMarkets
    ? Object.entries(selectedPrimeCheckboxStateInMarkets)?.every(([curMarket, curTestLevels]) => {
        return !!curTestLevels[testLevel];
      })
    : false;
};

const checkIsPartiallyCheckedPrimeCheckboxInMarkets = ({
  testLevel,
  wicNumber, // wicNumber in primeSkuTestLevelCheckboxes
  primeSkuTestLevelCheckboxes,
}: {
  testLevel: TestLevel;
  wicNumber: string;
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState;
}) => {
  const selectedPrimeCheckboxStateInMarkets = primeSkuTestLevelCheckboxes?.[
    wicNumber
  ] as PrimeTestLevelMarketCheckboxState;

  return _entries(selectedPrimeCheckboxStateInMarkets)?.some(
    (marketEntry) => marketEntry?.[1]?.[testLevel],
  );
};

function checkIsPrimeSku(
  editMode: boolean,
  isCurrent: boolean,
  isExistingPrime: boolean,
  isSelectedAsPrime: boolean,
) {
  let isPrimeSku = isExistingPrime || isSelectedAsPrime;

  if (editMode) {
    isPrimeSku =
      (isCurrent && isSelectedAsPrime) || (!isCurrent && isExistingPrime && isSelectedAsPrime);
  }

  return isPrimeSku;
}

function getSkuMarketTestLevelOptionStatuses(
  editMode: boolean,
  wicNumber: string,
  testLevel: TestLevel,
  skuGroupMappingTable: SkuGroupMappingTable,
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState,
) {
  const skuGroupTableSku = skuGroupMappingTable[wicNumber];

  const marketTestLevelOptionStatuses: SkuMarketTestLevelOptionStatus[] = [];

  if (skuGroupTableSku) {
    const { isExistingPrime, isSelectedAsPrime } = skuGroupTableSku;

    for (const [market, skuTestLevel] of Object.entries(skuGroupTableSku.markets)) {
      const isTestLevelCheckboxChecked =
        !!primeSkuTestLevelCheckboxes[wicNumber]?.[market]?.[testLevel];

      let isPrimeOptionStatus = false;

      if (editMode) {
        const isPrime = isExistingPrime && isSelectedAsPrime;

        isPrimeOptionStatus = isPrime && isTestLevelCheckboxChecked;
      } else {
        //create mode
        const isNewSkuPrimeOptionStatus =
          skuGroupTableSku?.isSelectedAsPrime && isTestLevelCheckboxChecked;

        isPrimeOptionStatus = isExistingPrime
          ? skuTestLevel[testLevel]?.isPrimeSku
          : isNewSkuPrimeOptionStatus;
      }

      const testLevelOptionStatus = skuTestLevel[testLevel]?.isNotApplicable
        ? TestLevelOptionStatus.NotApplicable
        : isPrimeOptionStatus
        ? TestLevelOptionStatus.Prime
        : TestLevelOptionStatus.Pim;

      marketTestLevelOptionStatuses.push([market, testLevelOptionStatus]);
    }
  }

  return marketTestLevelOptionStatuses;
}

// get wicNumber list for render sku group test level menu options
function getValidSkuGroupTestLevelWicNumberOptions({
  editMode,
  wicNumber,
  testLevel,
  skuGroupMappingTable,
  primeSkuTestLevelCheckboxes,
}: {
  editMode: boolean;
  wicNumber: string; // targeted test level wicNumber in skuGroup
  testLevel: TestLevel; // current sku group test level
  skuGroupMappingTable: SkuGroupMappingTable;
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState;
}): string[] {
  const validPrimeWicNumbers: string[] = [];

  const selectedSkuTestLevelOptionStatuses = getSkuMarketTestLevelOptionStatuses(
    editMode,
    wicNumber,
    testLevel,
    skuGroupMappingTable,
    primeSkuTestLevelCheckboxes,
  );

  const primeMappingSkuWicNumbers = Object.values(skuGroupMappingTable)
    ?.filter((mappingSku) => {
      const isPrimeSku = checkIsPrimeSku(
        editMode,
        mappingSku?.isCurrent,
        mappingSku?.isExistingPrime,
        mappingSku?.isSelectedAsPrime,
      );

      const isDeleted = mappingSku?.isDeleted;

      return isPrimeSku && !isDeleted;
    })
    .map((mappingSku) => mappingSku.wicNumber);

  if (!primeMappingSkuWicNumbers.length) {
    return validPrimeWicNumbers;
  }

  for (const primeSkuWicNumber of primeMappingSkuWicNumbers) {
    const primeSkuTestLevelOptionStatuses = getSkuMarketTestLevelOptionStatuses(
      editMode,
      primeSkuWicNumber,
      testLevel,
      skuGroupMappingTable,
      primeSkuTestLevelCheckboxes,
    );

    const primeSkuMarkets = primeSkuTestLevelOptionStatuses.map((status) => status[0]);

    const selectedSkuMarkets = selectedSkuTestLevelOptionStatuses.map((status) => status[0]);

    const isSelectedSkuMarketsCovered = selectedSkuMarkets.every((market) =>
      primeSkuMarkets.includes(market),
    );

    const isSelectedSkuFulfilled = selectedSkuTestLevelOptionStatuses.every(
      ([selectedSkuMarket, selectedSkuTestLevelStatus]) => {
        //look up prime market status
        const primeSkuMarketTestLevelStatus = primeSkuTestLevelOptionStatuses.find(
          ([primeSkuMarket]) => primeSkuMarket === selectedSkuMarket,
        );

        if (!primeSkuMarketTestLevelStatus) {
          return false;
        }

        const [primeSkuMarket, primeSkuTestLevelStatus] = primeSkuMarketTestLevelStatus;

        const isMatchPrimeStatus = primeSkuTestLevelStatus === TestLevelOptionStatus.Prime;

        const isMatchNotApplicableStatus =
          primeSkuTestLevelStatus === TestLevelOptionStatus.NotApplicable &&
          primeSkuTestLevelStatus === selectedSkuTestLevelStatus;

        return isMatchPrimeStatus || isMatchNotApplicableStatus;
      },
    );

    if (isSelectedSkuMarketsCovered && isSelectedSkuFulfilled) {
      validPrimeWicNumbers.push(primeSkuWicNumber);
    }
  }

  return validPrimeWicNumbers;
}

const assignSelectedGroupTestLevel = ({
  testLevel,
  optionWicNumber,
  selectedGroupWicNumber,
  selectedGroups,
}: {
  testLevel: TestLevel;
  optionWicNumber: string;
  selectedGroupWicNumber: string;
  selectedGroups: SkuTestLevelGroup[];
}) => {
  const cloneSelectedGroups = JSON.parse(JSON.stringify(selectedGroups)) as SkuTestLevelGroup[];

  const updatedSelectedGroups = cloneSelectedGroups?.map((group) => {
    if (group?.filters.wicNumber === selectedGroupWicNumber) {
      group.testLevels?.map((groupTestLevel) => {
        //* assign selected wicNumber if match test level & is applicable
        if (
          groupTestLevel.testLevel === testLevel &&
          !groupTestLevel?.defaultTestLevel?.isNotApplicable
        ) {
          groupTestLevel.selectedWicNumber = optionWicNumber;
          groupTestLevel.defaultTestLevel = null;
        }

        return groupTestLevel;
      });
    }

    return group;
  });

  return updatedSelectedGroups;
};

const removeSelectedGroupTestLevel = ({
  testLevel,
  selectedGroupWicNumber,
  selectedGroups,
}: {
  testLevel: TestLevel;
  selectedGroupWicNumber: string;
  selectedGroups: SkuTestLevelGroup[];
}) => {
  const cloneSelectedGroups = JSON.parse(JSON.stringify(selectedGroups)) as SkuTestLevelGroup[];

  const updatedSelectedGroups = cloneSelectedGroups?.map((group) => {
    if (group?.filters.wicNumber === selectedGroupWicNumber) {
      group.testLevels?.map((groupTestLevel) => {
        //* remove selectedWicNumber in selected test level if match test level
        if (groupTestLevel?.testLevel === testLevel) {
          groupTestLevel.selectedWicNumber = "";
        }

        return groupTestLevel;
      });
    }

    return group;
  });

  return updatedSelectedGroups;
};

//* use for remove selected prime sku test level in all selected groups when uncheck prime sku test level checkbox
const removeAllSelectedPrimeInSelectedGroupTestLevel = ({
  editMode,
  testLevel,
  selectedGroupWicNumber,
  selectedGroups,
}: {
  editMode: boolean;
  testLevel: TestLevel;
  selectedGroupWicNumber: string;
  selectedGroups: SkuTestLevelGroup[];
}) => {
  const cloneSelectedGroups = JSON.parse(JSON.stringify(selectedGroups)) as SkuTestLevelGroup[];

  const targetSelectedGroup = selectedGroups.find(
    (group) => group.filters.wicNumber === selectedGroupWicNumber,
  ) as SkuTestLevelGroup;

  const targetSelectedGroupTestLevel = targetSelectedGroup.testLevels.find(
    (groupTestLevel) => groupTestLevel.testLevel === testLevel,
  ) as SelectedTestLevel;

  const targetSelectedGroupTestLevelWicNumber =
    targetSelectedGroupTestLevel?.selectedWicNumber as string;

  // check if selected wicNumber matches its selected group test level
  // is prime test level, it should match
  const isSelectedGroupPrimeTestLevel =
    targetSelectedGroupTestLevelWicNumber === selectedGroupWicNumber;

  const updatedSelectedGroups = cloneSelectedGroups?.map((group) => {
    const isMatchCurrentGroup = group.filters.wicNumber === selectedGroupWicNumber;

    group.testLevels?.forEach((groupTestLevel) => {
      const isMatchTestLevel = groupTestLevel.testLevel === testLevel;

      if (isMatchTestLevel) {
        const isMatchGroupPrimeTestLevel = [
          groupTestLevel.selectedWicNumber,
          groupTestLevel.defaultTestLevel?.wicNumber,
        ].includes(selectedGroupWicNumber);

        //* create mode & matched prime sku
        const isCreateModeCondition = !editMode && isMatchGroupPrimeTestLevel;

        //* edit mode & is editing test level & matched prime sku
        const isEditModeCondition =
          editMode &&
          // (group?.filters?.isExistingPrime || group?.filters?.isSelectedAsPrime) &&
          groupTestLevel?.isEditing &&
          isMatchGroupPrimeTestLevel;

        const isMatchedTestLevelCondition = isCreateModeCondition || isEditModeCondition;

        //* if current test level is selected group wicNumber, remove all in selected groups,
        //* else only remove in current selected group
        if (
          (isSelectedGroupPrimeTestLevel && isMatchedTestLevelCondition) ||
          (!isSelectedGroupPrimeTestLevel && (isMatchCurrentGroup || isMatchGroupPrimeTestLevel))
        ) {
          groupTestLevel.selectedWicNumber = "";
          groupTestLevel.defaultTestLevel = null;
        }
      }

      return groupTestLevel;
    });

    return group;
  });

  return updatedSelectedGroups;
};

//* update select as prime checkbox in edit mode
const updateSelectAsPrimeCheckbox = ({
  selectedWicNumber,
  checked,
  selectAsPrimeCheckboxes,
}: {
  selectedWicNumber: string;
  checked: boolean;
  selectAsPrimeCheckboxes: Record<string, boolean>;
}) => {
  const cloneSelectAsPrimeCheckboxes = JSON.parse(
    JSON.stringify(selectAsPrimeCheckboxes),
  ) as Record<string, boolean>;

  const hasWicNumberInCheckboxes = Object.keys(cloneSelectAsPrimeCheckboxes).includes(
    selectedWicNumber,
  );

  // remove unchecked existing wicNumber
  if (hasWicNumberInCheckboxes && !checked) {
    const updated = _entries(cloneSelectAsPrimeCheckboxes)?.filter(([wicNumber]) => {
      return wicNumber !== selectedWicNumber;
    });

    return _fromPairs(updated) as Record<string, boolean>;
  }

  // add/update checked wicNumber
  cloneSelectAsPrimeCheckboxes[selectedWicNumber] = checked;

  return cloneSelectAsPrimeCheckboxes;
};

const updateSelectAsPrimeInSkuGroupMappingTable = ({
  selectedWicNumber,
  checked,
  skuGroupMappingTable,
}: {
  selectedWicNumber: string;
  checked: boolean;
  skuGroupMappingTable: SkuGroupMappingTable;
}) => {
  const cloneSkuGroupMappingTable = JSON.parse(
    JSON.stringify(skuGroupMappingTable),
  ) as SkuGroupMappingTable;

  cloneSkuGroupMappingTable[selectedWicNumber].color = checked ? randomColor() : "";
  cloneSkuGroupMappingTable[selectedWicNumber].isSelectedAsPrime = checked;

  return cloneSkuGroupMappingTable;
};

//* update prime checkbox state when select as prime checkbox changed
const updatePrimeTestLevelCheckboxes = ({
  editMode,
  isQualityFlow,
  checked,
  selectedWicNumber,
  primeSkuTestLevelCheckboxes,
  skuGroupMappingTable,
}: {
  editMode: boolean;
  isQualityFlow: boolean;
  checked: boolean;
  selectedWicNumber: string;
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState;
  skuGroupMappingTable: SkuGroupMappingTable;
}): PrimeTestLevelCheckboxState => {
  const clonePrimeSkuTestLevelCheckboxState = JSON.parse(
    JSON.stringify(primeSkuTestLevelCheckboxes),
  ) as PrimeTestLevelCheckboxState;

  const selectedSkuGroupMappingTable = skuGroupMappingTable[selectedWicNumber];

  const addedPrimeSkuTestLevlCheckboxes = convertMappingTableToPrimeTestLevelCheckboxes({
    editMode,
    isQualityFlow,
    skuGroupMappingTable: { [selectedWicNumber]: selectedSkuGroupMappingTable },
  });

  if (checked) {
    const updatedPrimeSkuTestLevelCheckboxState = {
      ...addedPrimeSkuTestLevlCheckboxes,
      ...clonePrimeSkuTestLevelCheckboxState,
    } as PrimeTestLevelCheckboxState;

    return updatedPrimeSkuTestLevelCheckboxState;
  }

  const filteredPrimeSkuTestLevelCheckboxState = _entries(
    clonePrimeSkuTestLevelCheckboxState,
  )?.filter(([primeSkuWicNumber]) => {
    return primeSkuWicNumber !== selectedWicNumber;
  });

  return _fromPairs(filteredPrimeSkuTestLevelCheckboxState) as PrimeTestLevelCheckboxState;
};

const checkIsAllSkuGroupingFilled = (
  isQualityFlow: boolean,
  skuGroupMaps: SkuGroupMap[],
): boolean => {
  if (!skuGroupMaps.length) return false;

  // push test level id in array by checking isQualityFlow from sku group maps
  const filteredTestLevelIds = skuGroupMaps?.reduce(
    (acc: Array<number | undefined>, skuGroupMap) => {
      for (const group of Object.entries(skuGroupMap)) {
        if (!isQualityFlow) {
          if (["taskAId", "taskFId", "taskLId", "taskPId"].includes(group[0])) {
            acc.push(group[1]);
          }
        } else if (isQualityFlow && ["taskQId", "taskCId"].includes(group[0])) {
          acc.push(group[1]);
        }
      }

      return acc;
    },
    [],
  );

  return filteredTestLevelIds.every((id) => id !== undefined);
};

/**
 * find task refer id by market, test level & wicNumber in selected sku test level group
 * @param SkuTestLevelGroup //skuTestLevelGroup
 * @param string //exportMarket
 * @param SkuGroupMappingTable //skuGroupMappingTable
 * @param testLevel
 * @return number | undefined
 */
const lookupMappingTableSkuReferToTaskId =
  (
    skuTestLevelGroup: SkuTestLevelGroup,
    exportMarket: string,
    skuGroupMappingTable: SkuGroupMappingTable,
    editMode: boolean,
  ) =>
  (testLevel: keyof typeof TestLevel): number | undefined => {
    const wicNumber = skuTestLevelGroup?.filters.wicNumber as string;

    // const isCurrentSelectedAsPrimeAndNoneExistingSku =
    //   skuTestLevelGroup.filters?.isCurrent &&
    //   skuTestLevelGroup.filters?.isSelectedAsPrime &&
    //   !skuTestLevelGroup.filters?.isExistingPrime;

    const isCurrentSku = skuTestLevelGroup.filters?.isCurrent;

    const selectedGroupSkuTestLevel = skuTestLevelGroup.testLevels?.find(
      (item) => item.testLevel === testLevel,
    ) as SelectedTestLevel;

    const testLevelSelectedWicNumber = selectedGroupSkuTestLevel?.selectedWicNumber as string;

    const isTestLevelMatchSkuGroupWicNumber = testLevelSelectedWicNumber === wicNumber;

    const currentMappingTableSku = skuGroupMappingTable[wicNumber];

    const selectedMappingTableSku = skuGroupMappingTable[testLevelSelectedWicNumber];

    const currentMappingTableSkuTestLevel =
      currentMappingTableSku?.markets[exportMarket][testLevel];

    const selectedMappingTableSkuTestLevel =
      selectedMappingTableSku?.markets[exportMarket][testLevel];

    const selectedMappingSkuTestLevelReferToTaskId =
      selectedMappingTableSkuTestLevel?.referToTaskId as number;

    // get pim sku task refer id from default test level in current sku group
    const selectedGroupDefaultTestLevelReferToTaskId =
      currentMappingTableSkuTestLevel?.referToTaskId as number;

    //* return rule for both mode
    // 1. if existing sku is in list, return its sku test level referToTaskId
    // 2. else if sku is not found in list & pim sku in default test level, retrun default test level referToTaskId
    const defaultReferToTaskId =
      selectedMappingSkuTestLevelReferToTaskId || selectedGroupDefaultTestLevelReferToTaskId;

    const getOriginalMarketTaskId = (skuGroupMappingSku: SkuGroupMappingSku): number => {
      return skuGroupMappingSku?.originalTask?.combinedMarketAndTestLevels?.find(
        (combined) => combined.exportMarket === exportMarket,
      )?.taskId as number;
    };

    // if NO test level wicNumber (expect null, i.e. Not Applicable) & NO defaultTestLevel, return undefined
    if (
      testLevelSelectedWicNumber !== null &&
      !testLevelSelectedWicNumber &&
      !selectedGroupSkuTestLevel?.defaultTestLevel
    ) {
      return undefined;
    }

    // edit mode logic
    if (editMode) {
      // if NA -> return current sku test level referToTaskId
      if (currentMappingTableSkuTestLevel?.isNotApplicable) {
        return currentMappingTableSkuTestLevel?.referToTaskId as number;
      }

      // 1. if current sku (no matter pim, selected as prime or existing prime)
      // 2. if selected wicNumber is same as current sku
      // 3. if does NOT have default test level
      // -> if current sku is prime & selected test level itself, return current market task id
      // due to current prime sku `currentMappingTableSkuTestLevel` & `selectedMappingTableSkuTestLevel` are pointing to the same sku (current prime sku), so need to refer its original task id
      if (
        isCurrentSku &&
        isTestLevelMatchSkuGroupWicNumber &&
        !selectedGroupSkuTestLevel?.defaultTestLevel
      ) {
        return getOriginalMarketTaskId(currentMappingTableSku);
      }

      return defaultReferToTaskId;
    }

    // create mode logic
    if (currentMappingTableSkuTestLevel?.isNotApplicable) {
      // if NA -> return current market sku id in originalTask.combinedMarketAndTestLevels
      return getOriginalMarketTaskId(currentMappingTableSku);
    }

    // pim sku in default test level -> return referToTaskId
    if (!selectedMappingSkuTestLevelReferToTaskId && !selectedGroupDefaultTestLevelReferToTaskId) {
      return getOriginalMarketTaskId(selectedMappingTableSku);
    }

    return defaultReferToTaskId;
  };

const convertSkuTestLevelGroupsToSkuGroupMaps = ({
  skuTestLevelGroups,
  skuGroupMappingTable,
  isQualityFlow,
  editMode,
}: {
  skuTestLevelGroups: SkuTestLevelGroup[];
  skuGroupMappingTable: SkuGroupMappingTable;
  isQualityFlow: boolean;
  editMode: boolean;
}): SkuGroupMap[] => {
  if (!skuTestLevelGroups.length) return [];

  const convertedSkuGroupMaps: SkuGroupMap[] = [];

  for (const skuTestLevelGroup of skuTestLevelGroups) {
    const { wicNumber, isDeleted, isCurrent, isExistingPrime, isSelectedAsPrime } =
      skuTestLevelGroup.filters;

    if (!isDeleted) {
      const loopMappingTableSku = skuGroupMappingTable[wicNumber];

      const isPrimeSku = checkIsPrimeSku(editMode, isCurrent, isExistingPrime, isSelectedAsPrime);

      const currentGroupWicNumber = skuTestLevelGroup?.filters?.wicNumber;

      const { list: loopMappingTableSkuMarkets } = lookupMappingSkuMarkets({
        markets: loopMappingTableSku?.markets,
      });

      if (!loopMappingTableSkuMarkets.length) return convertedSkuGroupMaps;

      const skuGroupMaps = loopMappingTableSkuMarkets.map((exportMarket) => {
        const marketMappingTableSkuTestLevel = loopMappingTableSku?.markets[exportMarket];

        // market taskId by filtering wicNumber & market
        const taskId = loopMappingTableSku?.originalTask?.combinedMarketAndTestLevels?.find(
          (combined) => combined.exportMarket === exportMarket,
        )?.taskId as number;

        const skuGroupMap: SkuGroupMap = {
          isPrimeSku,
          taskId,
          wicNumber: currentGroupWicNumber,
          exportMarket,
        };

        const mappingTableSkuTestLevelTaskReferId = lookupMappingTableSkuReferToTaskId(
          skuTestLevelGroup,
          exportMarket,
          skuGroupMappingTable,
          editMode,
        );

        //* get NA status from mapping table sku
        //* get test level task id from sku group
        if (isQualityFlow) {
          skuGroupMap.taskQId = mappingTableSkuTestLevelTaskReferId("Q");
          skuGroupMap.taskCId = mappingTableSkuTestLevelTaskReferId("C");

          skuGroupMap.isQNotApplicable =
            marketMappingTableSkuTestLevel?.Q?.isNotApplicable ?? false;
          skuGroupMap.isCNotApplicable =
            marketMappingTableSkuTestLevel?.C?.isNotApplicable ?? false;
        } else {
          skuGroupMap.taskAId = mappingTableSkuTestLevelTaskReferId("A");
          skuGroupMap.taskFId = mappingTableSkuTestLevelTaskReferId("F");
          skuGroupMap.taskLId = mappingTableSkuTestLevelTaskReferId("L");
          skuGroupMap.taskPId = mappingTableSkuTestLevelTaskReferId("P");

          skuGroupMap.isANotApplicable =
            marketMappingTableSkuTestLevel?.A?.isNotApplicable ?? false;
          skuGroupMap.isFNotApplicable =
            marketMappingTableSkuTestLevel?.F?.isNotApplicable ?? false;
          skuGroupMap.isLNotApplicable =
            marketMappingTableSkuTestLevel?.L?.isNotApplicable ?? false;
          skuGroupMap.isPNotApplicable =
            marketMappingTableSkuTestLevel?.P?.isNotApplicable ?? false;
        }

        return skuGroupMap;
      });

      convertedSkuGroupMaps.push(...skuGroupMaps);
    }
  }

  return convertedSkuGroupMaps;
};

function checkIsShowSkuCard(currentSelectedTestLevel: SelectedTestLevel): boolean {
  const hasSelectedTestLevel =
    currentSelectedTestLevel?.selectedWicNumber === null ||
    !!currentSelectedTestLevel?.selectedWicNumber;

  const hasDefaultTestLevel = !!currentSelectedTestLevel?.defaultTestLevel?.wicNumber;

  return hasSelectedTestLevel || hasDefaultTestLevel;
}

function checkHasNoSkuGroupingFilters(groupFilters: SkuGroupingFilters) {
  return !groupFilters.keyword && Object.values(groupFilters.filters).every((filter) => !filter);
}

function getFilteredSelectedGroupIndexMap(
  filteredSelectedGroups: SkuTestLevelGroup[],
): Map<string, number> {
  return new Map(filteredSelectedGroups.map((group, index) => [group.filters.wicNumber, index]));
}

function getFillingVerticalTestLevelInSkuGroupData({
  selectedGroupIndex, //target selected group index
  editMode,
  direction,
  selectedTestLevel,
  selectedOptionWicNumber,
  selectedGroups,
  filteredSelectedGroups,
  skuGroupingFilters,
  skuGroupMappingTable,
  primeSkuTestLevelCheckboxes,
}: {
  selectedGroupIndex: number;
  editMode: boolean;
  direction: "up" | "down";
  selectedTestLevel: TestLevel;
  selectedOptionWicNumber: string;
  selectedGroups: SkuTestLevelGroup[];
  filteredSelectedGroups: SkuTestLevelGroup[];
  skuGroupingFilters: SkuGroupingFilters;
  skuGroupMappingTable: SkuGroupMappingTable;
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState;
}) {
  const clonedSelectedGroups = deepClone(selectedGroups) as SkuTestLevelGroup[];

  const updatedTestLevelInSelectedGroups = clonedSelectedGroups.reduce(
    (
      acc: { testLevel: TestLevel; optionWicNumber: string; selectedWicNumber: string }[],
      currentSelectedGroup,
      currentGroupIndex,
    ) => {
      const currentSelectedGroupTestLevel = currentSelectedGroup.testLevels.find(
        (testLevel) => testLevel.testLevel === selectedTestLevel,
      );

      if (currentSelectedGroupTestLevel) {
        const currentSelectedGroupWicNumber = currentSelectedGroup.filters.wicNumber;

        const currentSelectedGroupTestLevelStatus = checkGroupTestLevelStatus({
          editMode,
          testLevel: selectedTestLevel,
          selectedWicNumber: currentSelectedGroupWicNumber,
          selectedGroupTestLevel: currentSelectedGroupTestLevel,
          skuGroupMappingTable,
        });

        const validDirection =
          (direction === "up" && currentGroupIndex < selectedGroupIndex) ||
          (direction === "down" && currentGroupIndex > selectedGroupIndex);

        const validTestLevelWicNumbers = getValidSkuGroupTestLevelWicNumberOptions({
          editMode,
          wicNumber: currentSelectedGroupWicNumber,
          testLevel: selectedTestLevel,
          skuGroupMappingTable,
          primeSkuTestLevelCheckboxes,
        });

        const isValidWicNumber = validTestLevelWicNumbers.includes(selectedOptionWicNumber);

        const baseVerticalFillingCondition =
          isValidWicNumber &&
          !currentSelectedGroupTestLevelStatus.isFullyNotApplicable &&
          !currentSelectedGroupTestLevelStatus.isFullyCompleted &&
          (!editMode || (editMode && currentSelectedGroupTestLevel.isEditing));

        const payload = {
          testLevel: selectedTestLevel,
          optionWicNumber: selectedOptionWicNumber,
          selectedWicNumber: currentSelectedGroupWicNumber,
        };

        const hasFiltering = !checkHasNoSkuGroupingFilters(skuGroupingFilters);

        if (hasFiltering) {
          // custom flow for filtered selected groups, iterating filtered group to check index if valid & check wic number if match current selected group
          for (
            let filteredGroupIndex = 0;
            filteredGroupIndex < filteredSelectedGroups.length;
            filteredGroupIndex++
          ) {
            const filteredSelectedGroup = filteredSelectedGroups[filteredGroupIndex];
            const isMatchSelectedGroupWicNumber =
              filteredSelectedGroup.filters.wicNumber === currentSelectedGroupWicNumber;

            const targetFilteredSelectedGroupIndex = getFilteredSelectedGroupIndexMap(
              filteredSelectedGroups,
            ).get(selectedGroups[selectedGroupIndex].filters.wicNumber);

            const validFilteredDirection =
              targetFilteredSelectedGroupIndex !== undefined &&
              ((direction === "up" && filteredGroupIndex < targetFilteredSelectedGroupIndex) ||
                (direction === "down" && filteredGroupIndex > targetFilteredSelectedGroupIndex));

            if (
              isMatchSelectedGroupWicNumber &&
              validFilteredDirection &&
              baseVerticalFillingCondition
            ) {
              acc.push(payload);
            }
          }
        } else if (validDirection && baseVerticalFillingCondition) {
          // normal flow for no filtered selected groups
          acc.push(payload);
        }
      }

      return acc;
    },
    [],
  );

  return updatedTestLevelInSelectedGroups;
}

function getFillingHorizontalTestLevelInSkuGroupData({
  selectedGroupIndex,
  editMode,
  direction,
  selectedTestLevel,
  selectedOptionWicNumber,
  selectedGroups,
  skuGroupMappingTable,
  primeSkuTestLevelCheckboxes,
}: {
  selectedGroupIndex: number;
  editMode: boolean;
  direction: "left" | "right";
  selectedTestLevel: TestLevel;
  selectedOptionWicNumber: string;
  selectedGroups: SkuTestLevelGroup[];
  skuGroupMappingTable: SkuGroupMappingTable;
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState;
}) {
  const clonedSelectedGroups = deepClone(selectedGroups) as SkuTestLevelGroup[];

  const currentSelectedGroup = clonedSelectedGroups[selectedGroupIndex];

  const currentSelectedGroupTestLevelIndex = currentSelectedGroup.testLevels.findIndex(
    (currentSelectedGroupTestLevel) =>
      currentSelectedGroupTestLevel.testLevel === selectedTestLevel,
  );

  const updatedTestLevelInSelectedGroups = currentSelectedGroup.testLevels.reduce(
    (
      acc: { testLevel: TestLevel; optionWicNumber: string; selectedWicNumber: string }[],
      currentSelectedTestLevel,
      currentTestLevelIndex,
    ) => {
      const currentSelectedGroupWicNumber = currentSelectedGroup.filters.wicNumber;

      const currentSelectedTestLevelStatus = checkGroupTestLevelStatus({
        editMode,
        testLevel: currentSelectedTestLevel.testLevel,
        selectedWicNumber: currentSelectedGroupWicNumber,
        selectedGroupTestLevel: currentSelectedTestLevel,
        skuGroupMappingTable,
      });

      const validDirection =
        (direction === "left" && currentTestLevelIndex < currentSelectedGroupTestLevelIndex) ||
        (direction === "right" && currentTestLevelIndex > currentSelectedGroupTestLevelIndex);

      const validTestLevelWicNumbers = getValidSkuGroupTestLevelWicNumberOptions({
        editMode,
        wicNumber: currentSelectedGroupWicNumber,
        testLevel: currentSelectedTestLevel.testLevel,
        skuGroupMappingTable,
        primeSkuTestLevelCheckboxes,
      });

      const isValidWicNumber = validTestLevelWicNumbers.includes(selectedOptionWicNumber);

      if (
        validDirection &&
        isValidWicNumber &&
        !currentSelectedTestLevelStatus.isFullyNotApplicable &&
        !currentSelectedTestLevelStatus.isFullyCompleted &&
        (!editMode || (editMode && currentSelectedTestLevel.isEditing))
      ) {
        acc.push({
          testLevel: currentSelectedTestLevel.testLevel,
          optionWicNumber: selectedOptionWicNumber,
          selectedWicNumber: currentSelectedGroupWicNumber,
        });
      }

      return acc;
    },
    [],
  );

  return updatedTestLevelInSelectedGroups;
}

function checkIsAllTestLevelCompleted(skuGroupMappingSku: SkuGroupMappingSku): boolean {
  // markets -> test levels -> should match sku wicNumber & completed
  const { wicNumber } = skuGroupMappingSku;

  return Object.values(skuGroupMappingSku.markets).every((marketTestLevels) =>
    Object.values(marketTestLevels).every((marketTestLevel) => {
      return marketTestLevel.isCompleted && marketTestLevel.wicNumber === wicNumber;
    }),
  );
}

function checkIsSomeTestLevelCompleted(skuGroupMappingSku: SkuGroupMappingSku): boolean {
  // markets -> test levels -> should match sku wicNumber & completed
  const { wicNumber } = skuGroupMappingSku;

  return Object.values(skuGroupMappingSku.markets).every((marketTestLevels) =>
    Object.values(marketTestLevels).some(
      (marketTestLevel) => marketTestLevel.isCompleted && marketTestLevel.wicNumber === wicNumber,
    ),
  );
}

function sortedSkuItemByExportMarket(list: any[]) {
  return list.sort(
    (a: any, b: any) => ORDERED_COUNTRIES[a.exportMarket] - ORDERED_COUNTRIES[b.exportMarket],
  );
}

export {
  isMatchCountryType,
  checkGroupingMatchFilterValues,
  getExistingSkuTaskDisabled,
  getExistingPrimeTaskStates,
  getExistingSelectedRowTaskIds,
  checkTestLevelTaskNotDone,
  getSkuTaskFilterValue,
  checkQualityFlow,
  getUniqueSelectedExportMarkets,
  checkAllSkuGroupsCompleted,
  mapExistingTestLevels,
  checkCreateSkuGrouping,
  sortTaskByCurrentTask,
  filterSkuGrouping,
  getUniqueTaskExportMarkets,
  getSelectedSkuDisabledIds,
  getExportMarketCombinations,
  mapSkusWithApplicableTestLevel,
  getDefaultTestLevelList, // wip test
  combineGroupingListData,
  mergeApplicableTestLevelsByMarket,
  groupByCombinedTaskSkuTestLevel,
  checkIsValidTestMatrix, // wip test
  checkIsSelectPrimeSubmitDisabled,
  convertTasksToSkuGroupMappingTable, // wip test
  convertMappingTableToSelectedGroups, // wip test
  editSelectAsPrimeSelectedGroups,
  convertMappingTableToPrimeTestLevelCheckboxes,
  convertMappingTableToSelectAsPrimeCheckboxes,
  hasNotApplicableSkuTestLevel,
  lookupMasterWicTaskByTaskId,
  checkGroupTestLevelStatus,
  updatePrimeSkuTestLevelCheckbox,
  checkPrimeSkuTestLevelCheckboxStatus,
  sortDefaultSelectedGroup,
  lookupMappingSkuMarkets, // wip test
  getSkuGroupingFilterOptions,
  checkIsFullyNotApplicableInMarkets,
  checkIsFullyPrimeInMarkets,
  checkIsPartiallyPrimeInMarkets,
  checkIsAllMarketsCheckedPrimeCheckbox,
  checkIsPartiallyCheckedPrimeCheckboxInMarkets,
  checkIsPrimeSku,
  getSkuMarketTestLevelOptionStatuses,
  getValidSkuGroupTestLevelWicNumberOptions,
  assignSelectedGroupTestLevel,
  removeSelectedGroupTestLevel,
  removeAllSelectedPrimeInSelectedGroupTestLevel, // added test
  updateSelectAsPrimeCheckbox,
  updateSelectAsPrimeInSkuGroupMappingTable,
  updatePrimeTestLevelCheckboxes,
  checkIsAllSkuGroupingFilled, // added test
  lookupMappingTableSkuReferToTaskId,
  convertSkuTestLevelGroupsToSkuGroupMaps, // wip test,
  checkIsShowSkuCard, // wip test
  checkHasNoSkuGroupingFilters,
  getFilteredSelectedGroupIndexMap,
  getFillingVerticalTestLevelInSkuGroupData,
  getFillingHorizontalTestLevelInSkuGroupData,
  checkIsAllTestLevelCompleted,
  checkIsSomeTestLevelCompleted,
  sortedSkuItemByExportMarket,
};
