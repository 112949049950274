/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useState } from "react";

import { Grid, styled } from "@mui/material";

import {
  AutocompleteOption,
  CheckboxesTagsOption,
  EditAutocomplete,
  EditAutocompleteCreatable,
  EditCheckboxedTags,
  EditDatePicker,
  EditTextField,
  Text,
  TextProps,
} from "components/core";

import TagGroupList from "components/TagGroupList";

import {
  COUNTRY_CODE_LIST_ACCESSOR,
  DC_LIST_ACCESSOR,
} from "components/core/Table/ReactTable/DefaultColumnFilter";

import { COUNTRY_CODE_OPTIONS, DC_CODE_OPTIONS } from "constants/item";
import { MappingGridData } from "types";
import { getArrayDisplayValue } from "utils/table";

const ReadOnlyText = styled(Text)({
  padding: "8px 8px 8px 0",
});

interface DataInputGridProps {
  items: MappingGridData[];
  disabled?: boolean;
  textProps?: Omit<TextProps, "children">;
  onInputSubmit?: (name: any, selected: string) => void;
}

function DataInputGrid({ items, disabled = false, textProps, onInputSubmit }: DataInputGridProps) {
  const [itemData, setItemData] = useState<MappingGridData[]>([]);

  useEffect(() => {
    setItemData(items);
  }, [items]);

  return (
    <Grid container spacing={2}>
      {itemData?.map((item: MappingGridData, index) => {
        const {
          isArray,
          name,
          value,
          label,
          inputReadOnly,
          inputOptions,
          placeholder,
          division,
          inputType,
          ...rest
        } = item;

        // * return values if is array
        if (isArray) {
          return (
            <Grid key={`${label}-${index}`} item {...rest}>
              <Text>{label}</Text>

              {(value as string[])?.map((target, targetIndex) => (
                <Text key={targetIndex} color="black">
                  {target}
                </Text>
              ))}
            </Grid>
          );
        }

        const isCountryCodeList = COUNTRY_CODE_LIST_ACCESSOR === name;
        const isDcList = DC_LIST_ACCESSOR === name;
        const isHasProp65 = name === "hasProp65";

        const countryCodeList = getArrayDisplayValue(
          COUNTRY_CODE_OPTIONS,
          value as string,
          "label",
        );

        const dcList = getArrayDisplayValue(DC_CODE_OPTIONS, value as string, "label");

        const getRenderValue = () => {
          if (isCountryCodeList) {
            return <TagGroupList list={countryCodeList} />;
          }

          if (isDcList) {
            return <TagGroupList list={dcList} />;
          }

          return value;
        };

        return (
          <React.Fragment key={index}>
            <Grid item {...rest}>
              <Text>{label}</Text>

              {inputType === "textField" ? (
                <EditTextField
                  disabled={disabled}
                  placeholder={placeholder || "Fill in info"}
                  readOnly={inputReadOnly}
                  name={name}
                  value={value as string}
                  textProps={textProps}
                  onSubmit={(selected) => onInputSubmit && onInputSubmit(name, selected)}
                />
              ) : inputType === "datePicker" ? (
                <EditDatePicker
                  disabled={disabled}
                  readOnly={inputReadOnly}
                  name={name}
                  value={value as string}
                  textProps={textProps}
                  onSubmit={(selected) => onInputSubmit && onInputSubmit(name, selected)}
                />
              ) : inputType === "autocomplete" ? (
                <EditAutocomplete
                  disabled={disabled}
                  sx={{ width: "250px" }}
                  placeholder={placeholder || "Select an item"}
                  readOnly={inputReadOnly}
                  options={inputOptions as AutocompleteOption[]}
                  value={value as string}
                  textProps={textProps}
                  onSubmit={(selected) => onInputSubmit && onInputSubmit(name, selected)}
                />
              ) : inputType === "autocompleteCreatable" ? (
                <EditAutocompleteCreatable
                  disabled={disabled}
                  sx={{ width: "250px" }}
                  placeholder={placeholder || "Select or add an item"}
                  readOnly={inputReadOnly}
                  options={inputOptions as AutocompleteOption[]}
                  value={value as string}
                  textProps={textProps}
                  onSubmit={(selected) => onInputSubmit && onInputSubmit(name, selected)}
                />
              ) : inputType === "checkboxesTags" ? (
                <EditCheckboxedTags
                  disabled={disabled}
                  sx={{ width: "250px" }}
                  denseTag
                  readOnly={inputReadOnly}
                  placeholder={placeholder || "Select an item"}
                  options={inputOptions as CheckboxesTagsOption[]}
                  value={value as string}
                  textProps={textProps}
                  onSubmit={(selected) => onInputSubmit && onInputSubmit(name, selected)}
                />
              ) : (
                <ReadOnlyText {...textProps}>{getRenderValue()}</ReadOnlyText>
              )}
            </Grid>

            {division && <Grid item xs={12} />}
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

export default memo(DataInputGrid);
