export default function camelCaseTextToWord(str: string | null = ""): string {
  if (!str) return "";

  if (typeof str !== "string") return str;

  if (str === "wicNumber") {
    return "PIM SKU SrcNum";
  }

  const specialWords = new Set(["Us", "Eu", "Uk", "Glb", "Qc"]);

  return str
    .split(/(?=[A-Z])/)
    .map((word) => {
      const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
      return specialWords.has(capitalizedWord) ? capitalizedWord.toUpperCase() : capitalizedWord;
    })
    .join(" ");
}
