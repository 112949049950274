import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, IconButton } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

import { useToggle } from "hooks";
import { useDeleteCustomerHeaders, useUpdateCustomerHeaders } from "hooks/useHeader";

import { BasicButton, BasicButtonProps, Text } from "components/core";

const TextButton = styled(BasicButton)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.primary.light,
  textTransform: "initial",
  padding: "6px 8px",
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  ul: {
    minWidth: "280px",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
}));

const NoActionMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  paddingTop: "12px",
  paddingBottom: "12px",
}));

export interface ManageColumnMenuOption {
  disabled?: boolean;
  isDefault: boolean;
  isCurrent: boolean;
  label: string;
  id: number;
  onEdit: (index: number) => void;
}

export interface ManageColumnMenuProps extends BasicButtonProps {
  label: string;
  menuList?: ManageColumnMenuOption[];
  onCreate: () => void;
  onClickDefaultView: () => void;
}

export default function ManageColumnMenu({
  label,
  menuList = [],
  onCreate,
  onClickDefaultView,
  ...rest
}: ManageColumnMenuProps) {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { isLoading: updateHeaderLoading, refetch: updateHeader } = useUpdateCustomerHeaders();
  const { isLoading: deleteLoading, refetch: deleteHeader } = useDeleteCustomerHeaders();

  const navigate = useNavigate();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleRemove = (id: number) => {
    deleteHeader({ id }).then(({ data }) => {
      if (data.code === 200) {
        navigate(0);
      }
    });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSetCurrentMenu = (id: number) => {
    updateHeader({
      id,
      isCurrent: true,
    }).then(({ data }) => {
      if (data.code === 200) {
        navigate(0);
      }
    });
  };

  return (
    <>
      <TextButton {...rest} id="manage-column-menu-button" variant="text" onClick={handleOpenMenu}>
        {label}
      </TextButton>

      <StyledMenu
        id="manage-column-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
        onClose={handleCloseMenu}
      >
        <NoActionMenuItem
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.text.secondary}`,
          })}
          onClick={(event) => {
            event.stopPropagation();

            onCreate();
          }}
        >
          Create a New View
        </NoActionMenuItem>

        {/*<NoActionMenuItem*/}
        {/*  onClick={(event) => {*/}
        {/*    event.stopPropagation();*/}

        {/*    onClickDefaultView();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Default View*/}
        {/*</NoActionMenuItem>*/}

        {menuList?.map((item, index) => (
          <StyledMenuItem
            sx={{
              display: "flex",
              flexFlow: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            data-cy={item.label}
            disabled={item?.disabled}
            key={item.id}
          >
            <Box onClick={(event) => handleSetCurrentMenu(item.id)} sx={{ zIndex: 999, flex: 1 }}>
              {item?.isCurrent ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text color="black">(Current View) &nbsp;</Text>

                  {item.label}
                </Box>
              ) : (
                item.label
              )}
            </Box>

            {!item?.isDefault && (
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => item.onEdit(index)}
                  sx={{ zIndex: 999 }}
                  disabled={updateHeaderLoading || deleteLoading}
                >
                  <EditIcon />
                </IconButton>
                {!item?.isCurrent && (
                  <IconButton
                    size="small"
                    onClick={() => handleRemove(item.id)}
                    sx={{ zIndex: 999 }}
                    disabled={updateHeaderLoading || deleteLoading}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
              </Box>
            )}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
